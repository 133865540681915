import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import axios from "../../../libs/axios";
import { Typography } from '@mui/material';
import {toast} from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  p: 4,
  zIndex: 500,
  maxHeight: '70%',
  width: '90%',
  maxWidth: 1300,
};
const TextFieldStyle = {
  width: '48%'
}


// j'ai une erreur pour le token 
export function ModalProject({ technician }) {

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    interventionType: 'shortenedSupport',
    interventionDate: new Date().toISOString().split('T')[0],
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.log(technician)
  const handleSave = () => {
   const formDataNamePattern = /^[A-Za-z0-9 éèêëàâäîïôöûüç'&()-]*$/;
    if (!formDataNamePattern.test(formData.name)) {
      alert("N'accepte pas les caractères ! @ # $ % ^ \" * _ + = < > ? ; : , . / \\ { } [ ]")
      return;
    }
    if (formData.name.trim() === '') {
      alert("Le champ 'nom du projet' est obligatoire");
      return;
    }
    const postData = {
      ...formData,
      workspace: 1,
      phase: 'interventionToBePerformed',
      technician: technician,
      buildings: [{
        name: formData.name,
        propertyType:"residential"
      }],
    };
    // quand je met le domaine j'ai toujours in localhost avant 
    axios.post(`/project`,
      postData,
    )
      .then((resp) => {
        handleClose();
        window.location.reload()
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    (navigator.onLine) ?
    <div>
      <Button sx={{ margin: "50px auto", display: "flex", backgroundColor: '#FE8403' }} variant="contained" onClick={handleOpen}>Créer une Intervention</Button>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <TextField
            label="Nom du projet"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            select
            label="Type d'intervention"
            name="interventionType"
            value={formData.interventionType}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
          >
            <MenuItem value="shortenedSupport">Prise en charge</MenuItem>
            <MenuItem value="completeSupport">Prise en charge expert</MenuItem>
          </TextField>
          <TextField
            label="Date d'intervention"
            name="interventionDate"
            type="date"
            value={formData.interventionDate}
            onChange={handleInputChange}
            defaultValue={new Date().toISOString().split('T')[0]}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Typography sx={{textAlign:'center', fontWeight:'500',fontSize:'20px', margin:'20px'}}>
            Adresse
          </Typography>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <TextField
              label="Numéro de voie"
              name="streetNumber"
              type="text"
              value={formData.streetNumber}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
              sx={TextFieldStyle}
            />
            <TextField
              label="Nom de voie"
              name="streetName"
              type="text"
              value={formData.streetName}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
              sx={TextFieldStyle}
            />
          </div>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <TextField
              label="Code postal"
              name="zipcode"
              type="text"
              value={formData.zipcode}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
              sx={TextFieldStyle}
            />
            <TextField
              label="Ville"
              name="city"
              type="text"
              value={formData.city}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
              sx={TextFieldStyle}
            />
          </div>

          <div style={{ display:'flex', justifyContent:'space-around', margin:'10px'}}>
          <Button sx={{ color:'grey'}} onClick={handleClose}>Annuler</Button>
          <Button variant="outlined" sx={{ color:'#FE8403', borderColor:'#FE8403', '&:hover': { borderColor:'#FE8403'}}}  onClick={handleSave}>Enregistrer</Button>
          </div>
        </Box>
      </Modal>
    </div> :
    null
  );
}
