import React, {Component} from 'react';
import {withRouter} from "react-router";
import {bindActionCreators} from 'redux';
import * as UserActions from "../redux/actions/user";
import * as HeadersActions from "../redux/actions/headers";
import * as InventoryActions from "../redux/actions/inventory";
import * as NetworkActions from "../redux/actions/network";
import {connect} from "react-redux";
import Input from "./Input";
import _ from "lodash";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

class SmokeDuctDiameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: props.isLoading ? props.isLoading : false,
      currentMenu: props.currentMenu,
      currentSubMenu: props.currentSubMenu,
      diameters: props.diameters && props.diameters.length > 0 ? props.diameters : [0],
    };
  }


  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.isLoading && nextProps.isLoading !== this.state.isLoading) {
      this.setState({
        isLoading: nextProps.isLoading
      })
    }
  }


  handleClassicChange = (field, value, index) => {
    let {diameters} = this.state;
    diameters[index] = value;
    this.setState({diameters}, () => {
      this.props.diametersChanged(this.props.field, this.state.diameters);
    });
  };

  addDiameter = () => {
    let {diameters} = this.state;
    diameters.push(0);
    this.setState({diameters}, () => {
      this.props.diametersChanged(this.props.field, this.state.diameters);
    });
  };


  deleteDiameter = (index) => {
    let {diameters} = this.state;
    let tmpArray = _.cloneDeep(diameters);
    tmpArray.splice(index, 1);
    this.setState({diameters: tmpArray}, () => {
      this.props.diametersChanged(this.state.diameters, this.props.name, this.props.index);

    });
  };

  render() {

    let {diameters} = this.state;

    let diametersSection = diameters ? (
      <section className={'circuit-form-container'}>
        <Typography variant="h6" component={'span'}>Les diamètres des conduits de fumées :</Typography>
        {
          diameters.map((eachOne, index) => {
            return (
              <section key={index} className={'present-persons-form'}>

                <Input
                  label={'Diamètre n° ' + (index + 1) + ' (cm)'}
                  name={'diameter'+index}
                  index={index}
                  type={'number'}
                  value={eachOne}
                  onChange={this.handleClassicChange}/>
                {
                  (diameters.length > 0) ? (
                    <Tooltip title="Supprimer ce circuit" aria-label="Delete"
                             onClick={() => this.deleteDiameter(index)}>
                      <Fab color="secondary" aria-label="Delete" size={'small'}>
                        <DeleteIcon/>
                      </Fab>
                    </Tooltip>
                  ) : ''
                }

              </section>
            )

          })
        }


                <div style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px'
                }}>
                  <Tooltip title="Ajouter un circuit" aria-label="Add" onClick={() => this.addDiameter()}>
                    <Fab color="primary" aria-label="Add" size={'small'}>
                      <AddIcon/>
                    </Fab>
                  </Tooltip>
                </div>

      </section>
    ) : '';

    return (
      <section>
        {diametersSection}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  currentUser: state.user.user,
  refreshToken: state.headers.refreshToken,
  backUrl: state.constants.backUrl,
  inventory: state.inventory.inventory,
  network: state.network.network
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch),
  networkActions: bindActionCreators(NetworkActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmokeDuctDiameter));
