import Button from '@material-ui/core/Button'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as HeadersActions from '../../redux/actions/headers'
import * as UserActions from '../../redux/actions/user'

class NotFoundComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

  }

  render() {
    return (
      <div className='errorBody'>
        <section className='errorContainer'>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <img
              src='/logo.png'
              width='200'
              style={{ display: 'block', margin: '20px auto' }}
              alt='Logo'

            />
          </Link>

          <span className='errorTextContainer'>
            <span className='errorTextImportant'>OUPS!</span>
            <br />
            <span className='errorText'>La page que vous avez demandé n'existe pas :(</span>
          </span>
          <Link to='/'>
            <Button variant='outlined' color='secondary'>
              Retour à la page d'accueil
            </Button>
          </Link>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  refreshToken: state.headers.refreshToken,
  currentUser: state.user.user,
  backUrl: state.constants.backUrl
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotFoundComponent))
