import {SET_METAS, SET_ALL_METAS} from '../constants/metaTypes'

const initialState = {
  metas: {},
  all: null
};

const metas = (state = initialState, action) => {
  switch (action.type) {
    case SET_METAS:
      return {
        ...state,
        metas: action.value
      };
    case SET_ALL_METAS:
      return {
        ...state,
        all: action.value
      };
    default:
      return state
  }
};

export default metas;
