import Button from "@material-ui/core/Button";
import MyLocation from '@material-ui/icons/MyLocation';
import React from 'react';
import { geolocated } from "react-geolocated";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as HeadersActions from "../../redux/actions/headers";
import * as InventoryActions from "../../redux/actions/inventory";
import * as UserActions from "../../redux/actions/user";


class SyncGeoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: null,
      longitude: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.coords && this.state.latitude !== nextProps.coords.latitude && this.state.longitude !== nextProps.coords.longitude) {
      this.setState({
        latitude: nextProps.coords.latitude,
        longitude: nextProps.coords.longitude
      })
    }
  }

  syncGeo = () => {
    if (this.props.geoSynchronized) {
      this.props.geoSynchronized(this.state.latitude, this.state.longitude);
    }
  };

  render() {
    return (
      <div>
        <Button variant="contained"
                className={'secondary-btn'}
                onClick={this.syncGeo}
                color={'secondary'}
        >
          {this.props.label}
          <MyLocation style={{marginLeft: '10px'}}/>
        </Button>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  refreshToken: state.headers.refreshToken,
  currentUser: state.user.user,
  backUrl: state.constants.backUrl,
  inventory: state.inventory.inventory
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(
  (geolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: Infinity,
    },
    watchPosition: false,
    userDecisionTimeout: null,
    suppressLocationOnMount: false,
    geolocationProvider: navigator.geolocation,
    isOptimisticGeolocationEnabled: true
  }))(SyncGeoComponent)));
