import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PowerSettingsNewOutlined from '@material-ui/icons/PowerSettingsNewOutlined';
import cookie from 'js-cookie';
import React from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import * as HeadersActions from "../../redux/actions/headers";
import * as UserActions from "../../redux/actions/user";

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let currentUserEmail = cookie.get('currentUser') ? JSON.parse(cookie.get('currentUser')).email : null;
    if (cookie.get('currentUser')) {
      let currentName = currentUserEmail.split('@')
      this.setState({ currentName });
    }
    if (!cookie.get('apiKey') || !cookie.get('currentUser') || !cookie.get('refreshToken')) {
      this.props.history.push('/login');
    }
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose() {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    cookie.remove('expireRefresh');
    cookie.remove('refreshToken');
    cookie.remove('apiKey');
    this.props.history.push('/login');
  };


  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <header className="header">
        <Link to={'/'} className="header__logo">
          <img alt="logo" src="/logo.png" width="120" />
          {/* <Logo style={{ width: 120 }} /> */}
        </Link>
        <div className="header__title">
          <h1 className="header__title">
            {this.props.title ? this.props.title : ''}
          </h1>
        </div>
        <div className="header__disconnect">
          <Button color="primary" variant="contained" onClick={this.handleMenu} style={window.innerWidth < 500 ? { color: 'white', width: '110px', fontSize: '12px' } : { color: 'white' }}>
            {this.state.currentName ? this.state.currentName[0] : ''}
            <AccountCircle style={{ marginLeft: '10px' }} />
          </Button>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl} ê
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={open}
            onClose={this.handleClose}
            style={{
              top: '45px',
              left: '0'
            }}
          >
            <MenuItem
              style={{
                background: 'transparent',
                color: '#FE8403',
                fontWeight: 'bold',
                padding: '0 30px'
              }}
              onClick={this.logout}>Déconnexion
              <PowerSettingsNewOutlined
                style={{ marginLeft: '10px' }} />
            </MenuItem>
          </Menu>
        </div>
      </header>
    );
  };
}


const mapStateToProps = state => ({
  refreshToken: state.headers.refreshToken,
  apiKey: state.headers.apiKey,
  currentUser: state.user.user,
  backUrl: state.constants.backUrl
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderComponent));
