import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';

import store from './redux/store';
import {Provider} from 'react-redux';
import './libs/i18n';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

const theme = createMuiTheme({
  palette: {
    primary: {main: '#FE8403'},
    secondary: {
      main: '#113BC9',
    },
    third: {
      main: 'red',
    }
  },
  typography: {
    useNextVariants: true
  }
});

Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN,   environment: process.env.REACT_APP_SENTRY_ENV});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App/>
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
