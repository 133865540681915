import React from 'react'
import PropTypes from 'prop-types'
import {DatePicker} from 'material-ui-pickers'
import moment from 'moment';
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

moment.locale('fr');

class MyDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? moment(new Date(props.value)) : moment()
    };
  }

  componentDidMount() {
    this.props.onChange(this.props.name, this.state.value, this.props.index);
  }

  onChange = (value) => {
    this.setState({value}, () => {
      this.props.onChange(this.props.name, this.state.value.format(), this.props.index);
    });
  }

  render() {
    return (
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale='fr'
        moment={moment}>
        <div style={{
          marginTop: '15px',
          marginBottom: '30px'
        }}>
          <DatePicker
            keyboard
            clearable
            variant="outlined"
            style={{
              width: '100%'
            }}
            label={this.props.label}
            value={this.state.value}
            format='DD/MM/YYYY'
            onChange={this.onChange}
          />
        </div>
      </MuiPickersUtilsProvider>
    )
  }
}

MyDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default MyDatePicker
