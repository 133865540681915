import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Promise from 'bluebird';
import CamelCase from 'camelcase';
import core from "core";
import ls from "local-storage";
import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import Actions from "../../Fields/Actions";
import inventoryService from "../../Services/InventoryService";
import * as HeadersActions from "../../redux/actions/headers";
import * as InventoryActions from "../../redux/actions/inventory";
import * as NetworkActions from "../../redux/actions/network";
import * as UserActions from "../../redux/actions/user";
import populateData from '../../util/populate';
import Header from "../Header/HeaderComponent";
import axios from './../../libs/axios';

class InventoryMenuComponent extends React.Component {
  constructor(props) {
    super(props);
    let populate = '';
    populateData.data.forEach((item, index) => {
      populate += item;
      if (index < populateData.data.length - 1) {
        populate += ',';
      }
    });


    this.state = {
      inventoryId: _.get(props, 'match.params.id'),
      displayFinishState: true,
      urlParams: {
        populate: populate,
        page: 1,
        limit: 20,
        technician: _.get(props, 'currentUser._id', null),
        stateOfProgress: 'programmed'
      }
    };
  }

  componentDidMount() {
    let { inventoryId } = this.state;
    if (!inventoryId) {
      this.props.history.push('/');
    } else {
      let isOnline = navigator.onLine;
      this.setState({ technician: _.get(this.props, 'currentUser._id', false) }, () => {
        // GET DATA
        Promise.props({
          inventories: isOnline && !ls('push') ? inventoryService.getRemoteInventories(this.state.urlParams) : inventoryService.getLocalInventories(),
          metas: isOnline && !ls('push') ? inventoryService.getRemoteMetas() : inventoryService.getLocalMetas()
        }).then(resp => {
          if (isOnline && !ls('push')) {
            // CLEAN DB
            Promise.props({
              resetDataBase: inventoryService.resetDataBases()

            }).then(() => {
              // UPDATE DB
              Promise.props({
                setLocalInventories: inventoryService.setLocalInventories(resp.inventories),
                setLocalMetas: inventoryService.setLocalMetas(resp.metas),
              }).then(res => {
                let exist = false;
                resp.inventories.forEach(project => {
                  if (_.get(project, 'boilerRoomInventory.id', false) === parseInt(inventoryId, 10)) {
                    exist = project
                  }
                });
                if (exist) {
                  this.setState({ inventory: exist, menuGenResult: core.menuGenerator(exist, [], exist.interventionType === 'completeSupport' ? 'complete' : 'shortened') })
                }
              })
            })
          } else {
            let exist = false;
            resp.inventories.forEach(project => {
              if (_.get(project, 'boilerRoomInventory.id', false) === parseInt(inventoryId, 10)) {
                exist = project
              }
            });
            if (exist) {
              this.setState({ inventory: exist, menuGenResult: core.menuGenerator(exist, [], exist.interventionType === 'completeSupport' ? 'complete' : 'shortened') })
            }
          }
        })
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.menuGenResult) {
      if (!this.state.stopAnchor) {
        this.setState({ stopAnchor: true }, () => {
          let anchorHash = this.props.location.hash;
          window.location.hash = '';
          window.location.hash = anchorHash;
        })
      }
    }
  }

  getCustomizedSubMenuTitle = (menu, subMenu) => {
    let customizedTitle = subMenu.name.fr;
    let { inventory } = this.state;
    switch (menu.url) {
      case "ecs-production":
        switch (subMenu.url) {
          case 'preparer':
            let ecsPreparerType = _.get(inventory, 'ecsProduction.ecsProductionView.ecsPreparator', null);
            if (ecsPreparerType) {
              customizedTitle += ' - ' + this.props.t(ecsPreparerType);
            }
            break;
          default:
        }
        break;
      default:

    }
    return customizedTitle;
  };


  handleFinishedChange = () => {
    let { inventory } = this.state;
    inventory.isFinished = !(inventory.isFinished);

    this.setState({
      displayFinishState: false,
      inventory
    }, () => {
      inventoryService.setLocalInventory(inventory)
      if (navigator.onLine) {
        axios.put('/inventories/' + inventory._id, { isFinished: inventory.isFinished }).then(() => {
          this.setState({
            displayFinishState: true
          }, () => {
            toast.success('EDL marqué comme ' + (inventory.isFinished === true ? 'terminé' : "n'est pas terminé"))
          })
        })
      } else {
        this.setState({
          displayFinishState: true
        }, () => {
          toast.success('EDL marqué comme ' + (inventory.isFinished === true ? 'terminé' : "n'est pas terminé"))
        })
      }
    })
  }

  render() {
    let { inventory, menuGenResult } = this.state;
    if (window.tidioChatApi) {
      window.tidioChatApi.hide()
    }
    let menuSection = menuGenResult ? (
      <div>
        {
          Object.keys(menuGenResult).map((menu) => {
            return (
              <Grow in={true} key={menu}>
                <Paper elevation={1} style={{
                  width: '70%',
                  margin: '0 auto',
                  marginBottom: '20px',
                  padding: '20px 50px',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '20px'
                }}>
                  <Typography variant="h5" component="h5" paragraph={true} style={{
                    textAlign: 'center'
                  }}>
                    {_.get(menuGenResult, menu + '.label', false)}
                  </Typography>
                  {
                    Object.keys(_.get(menuGenResult, menu + '.subMenus', {})).map((subMenu, index) => {
                      return (
                        <div
                          id={menu + '_' + subMenu}
                          key={menu + '.' + subMenu}
                          style={{
                            display: 'flex'
                          }}>
                          <div
                            style={{
                              width: '40px'
                            }}>
                            {
                              inventory.checkedSections && inventory.checkedSections.indexOf(CamelCase(menu) + '.' + CamelCase(subMenu)) > -1 ? (
                                <CheckCircleRoundedIcon
                                  color={"primary"}
                                />) : ''
                            }
                          </div>

                          <Link
                            to={'/' + menu + '/' + subMenu + '/' + this.state.inventory.boilerRoomInventory.id}
                            key={menu + '.' + subMenu + '.link'}
                          >
                            <Typography variant={'subtitle1'} component="span"
                              paragraph={true}>
                              {
                                _.get(menuGenResult, menu + '.subMenus.' + subMenu + '.label')
                              }
                            </Typography>
                          </Link>
                        </div>
                      )
                    })
                  }
                </Paper>
              </Grow>
            )
          })
        }
      </div>
    ) : ''

    let spinnerSection = (!inventory) ? (
      <section className={'spinner-container'}>
        <CircularProgress
          size={70}
          color={'secondary'}
        />
      </section>
    ) : '';

    return (
      <section className={'component-container'}>

        <Header title={inventory ? inventory.name : ''} />


        <div className={'component-container-body'}>
          {menuSection}
        </div>

        <div style={{
          position: "fixed",
          top: "86%",
          width: "150px",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70px',
          right: "120px",

        }}
        >

          {
            /*
            this.state.displayFinishState &&  inventory? ((_.get(inventory, 'isFinished', false) === true) ? (
                <Button variant="outlined" color="secondary"

                        onClick={this.handleFinishedChange}
                >
                  N'est pas terminé
                </Button>
              ) : (
                <Button variant="outlined" color="primary"
                        onClick={this.handleFinishedChange}
                >
                  Terminé
                </Button>
              )
            ) : ''
            */
          }
        </div>


        <Actions />
        {spinnerSection}

      </section>


    );
  }
}


const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  refreshToken: state.headers.refreshToken,
  currentUser: state.user.user,
  backUrl: state.constants.backUrl,
  inventory: state.inventory.inventory,
  network: state.network.network
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch),
  networkActions: bindActionCreators(NetworkActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(InventoryMenuComponent)));
