import Promise from 'bluebird';
import cookie from 'js-cookie';
import ls from 'local-storage';
import localforage from "localforage";
import _ from 'lodash';
import moment from "moment";
import axios from '../libs/axios';

class InventoryService {

  localMedia;
  localInventory;
  localMetas;


  constructor() {
    this.localMedia = localforage.createInstance({
      name: "localMedia"
    });
    this.localInventory = localforage.createInstance({
      name: "localInventory"
    });
    this.localProjects = localforage.createInstance({
      name: "localProjects"
    });
    this.localMetas = localforage.createInstance({
      name: "localMetas"
    });
    this.localVentilation = localforage.createInstance({
      name: "localVentilation"
    });
    this.localAirConditioning = localforage.createInstance({
      name: "localAirConditioning"
    });

  }

  setLocalProjects = (inventoryArray) => {
    let setPromise = [];
    return this.localProjects.clear().then(async () => {
      inventoryArray.forEach(item => {
        setPromise.push(this.localProjects.setItem((item?.id).toString(), item))
      });
      const resultPromise = await Promise.all(setPromise).then(result => {
        return result;
      })
      return resultPromise
    })

  };

  // setLocalInventories = (inventoryArray) => {
  //   let setPromise = [];
  //   return this.localInventory.clear().then(async () => {
  //     inventoryArray.forEach(item => {
  //       setPromise.push(this.localInventory.setItem((item?.id).toString(), item))
  //       setPromise.push(this.localVentilation.setItem((item?.id).toString(), item))
  //     });
  //     const resultPromise = await Promise.all(setPromise).then(result => {
  //       return result;
  //     })
  //     return resultPromise
  //   })

  // };
  setLocalInventories = (inventoryArray) => {
    let setPromise = [];

    return Promise.all([
      this.localInventory.clear(),
      this.localVentilation.clear(),
      this.localAirConditioning.clear()
    ]).then(async () => {
      inventoryArray.forEach(item => {
        setPromise.push(this.localInventory.setItem((item?.id).toString(), item));
        setPromise.push(this.localVentilation.setItem((item?.id).toString(), item));
        setPromise.push(this.localAirConditioning.setItem((item?.id).toString(), item));
      });

      const resultPromise = await Promise.all(setPromise).then(result => {
        return result;
      });

      return resultPromise;
    });
  };

  setLocalInventory = (inventory) => {
    const inventoryPro = this.localInventory.setItem((inventory?.id).toString(), inventory).then(inserted => inserted);
    const ventilPro = this.localVentilation.setItem((inventory?.id).toString(), inventory).then(inserted => inserted);
    const airConditioningPro = this.localAirConditioning.setItem((inventory?.id).toString(), inventory).then(inserted => inserted);
    return Promise.all([inventoryPro, ventilPro, airConditioningPro]);
  };

  setLocalMetas = (metasArray) => {
    return []
    // let setPromise = [];
    // return this.localMetas.clear().then(() => {
    //   if (metasArray) {
    //     metasArray.forEach(item => {
    //       setPromise.push(this.localMetas.setItem((item.id).toString(), item))
    //     })
    //   }
    //   return Promise.all(setPromise).then(result => {
    //     return result;
    //   })
    // })
  };

  setEnergies(inventory) {
    let isFioul = false;
    let isGaz = false;
    let isRcu = false;

    let boilerEnergy = _.get(inventory, "generalInformations.visitInformations", null);
    if (boilerEnergy) {

      switch (boilerEnergy.isEcsProdCoupled) {
        case 'separate':
          boilerEnergy.energyType = null;
          break;
        case 'shared':
          boilerEnergy.heatingType = null;
          boilerEnergy.ecsType = null;
          break;
        default:
      }

      if (_.get(inventory, "generalInformations.visitInformations.isEcsProdCoupled") === 'separate') {
        if ((boilerEnergy.heatingType && boilerEnergy.heatingType.indexOf('oil') > -1) || (boilerEnergy.ecsType && boilerEnergy.ecsType.indexOf('oil') > -1)) {
          isFioul = true;
        }

        if ((boilerEnergy.heatingType && boilerEnergy.heatingType.indexOf('rcu') > -1) || (boilerEnergy.ecsType && boilerEnergy.ecsType.indexOf('rcu') > -1)) {
          isRcu = true;
        }

        if ((boilerEnergy.heatingType && boilerEnergy.heatingType.indexOf('naturalGas') > -1) || (boilerEnergy.ecsType && boilerEnergy.ecsType.indexOf('naturalGas') > -1)) {
          isGaz = true;
        }
      }

      if (
        (_.get(inventory, "generalInformations.visitInformations.isEcsProdCoupled") === 'shared'
          ||
          _.get(inventory, "generalInformations.visitInformations.isEcsProdCoupled") === 'individual'
        ) && boilerEnergy.energyType
      ) {
        if (boilerEnergy.energyType.indexOf('naturalGas') > -1) {
          isGaz = true;
        }

        if (boilerEnergy.energyType.indexOf('oil') > -1) {
          isFioul = true;
        }

        if (boilerEnergy.energyType.indexOf('rcu') > -1) {
          isRcu = true;
        }
      }
    }
    _.set(inventory, 'isGaz', isGaz);
    _.set(inventory, 'isFioul', isFioul);
    _.set(inventory, 'isRcu', isRcu);
    return inventory;
  }

  setMetas(meta) {
    // if (meta) {
    //   return this.localMetas.setItem(meta._id, meta).then(inserted => inserted);
    // }
    return []
  }

  // Getters
  getLocalInventories = async () => {
    let result = [];
    const resp = await this.localInventory.iterate((value, key, index) => {
      result.push(value)
    })
      .then(() => {
        for (let i = 0; i < result.length - 1; i++) {
          for (let j = i + 1; j < result.length; j++) {
            if (moment(result[i].interventionDate).isBefore(result[j].interventionDate)) {
              let tmpObject = _.cloneDeep(result[i]);
              result[i] = _.cloneDeep(result[j]);
              result[j] = _.cloneDeep(tmpObject);
            }
          }
        }
        return result;
      });
    return resp
  };

  // Get all local métas
  getLocalMetas() {
    // let result = [];
    // return this.localMetas.iterate((value, key, index) => {
    //   result.push(value)
    // }).then(() => {
    //   return result;
    // });
    return []
  };

  getLocalNewMetas() {
    return []
    // let result = [];
    // return this.localMetas.iterate((value, key, index) => {
    //   if (value.id) {
    //     result.push(value)
    //   }
    // }).then(() => {
    //   return result;
    // });
  };

  getMetasByType(metas, type) {
    return []
    // let result = [];
    // if (metas) {
    //   metas.forEach(item => {
    //     if (item.type === type) {
    //       result.push(item)
    //     }
    //   });
    // }
    // return result;
  }

  getRemoteMetas() {
    return []
    // return axios.get('/metas/all').then(res => res.data.data)
  }

  getRemoteInventories = () => {
    const params = {}
    params.sort = 'ASC';
    params.order = 'interventionDate';
    params.phase = 'interventionToBePerformed;reportsToBeFinalized'
    let currentUser = cookie.get('currentUser');
    if (currentUser && _.get(JSON.parse(currentUser), '_id', false)) {
      params.technician = JSON.parse(currentUser)._id;
    }
    return axios.get('/projects/all', { params }).then(resp => {
      let projects = _.get(resp, 'data', []);
      return Promise.map(projects, (project) => {
        if (_.get(project, 'boilerRoomInventory.id', false)) {
          const boilerRoomInventoryId = _.get(project, 'boilerRoomInventory.id');
          const ventilationInventoryId = _.get(project, 'ventilationInventory.id');
          const airConditioningInventoryId = _.get(project, 'airConditioningInventory.id');
          const boilerRoomRequest = axios.get('/boiler-room-inventory/' + boilerRoomInventoryId);
          const ventilationRequest = axios.get('/ventilation-inventory/' + ventilationInventoryId);
          const airConditioningRequest = axios.get('/airConditioning-inventory/' + airConditioningInventoryId);
          return Promise.all([boilerRoomRequest, ventilationRequest, airConditioningRequest]).then(([boilerRoomRes, ventilationRes, airConditioningRes]) => {
            project = {
              ...project,
              ...ventilationRes.data,
              ...airConditioningRes.data,
              ...boilerRoomRes.data,
              idVentilationInventory: ventilationRes.data.id,
              idAirConditioningInventory: airConditioningRes.data.id
            };
            return project;
          })
        }
        return project
      })
    })
  };

  // getRemoteInventory = (id, params) => {
  //   return axios.get('/boiler-room-inventory/' + id);
  // };
  getRemoteInventory = ({ boilerRoomId, ventilationId, airConditioningId }) => {
    const boilerRoomRequest = axios.get('/boiler-room-inventory/' + boilerRoomId);
    const ventilationRequest = axios.get('/ventilation-inventory/' + ventilationId);
    const airConditioningRequest = axios.get('/airConditioning-inventory/' + airConditioningId);

    return Promise.all([boilerRoomRequest, ventilationRequest, airConditioningRequest])
      .then(([boilerRoomRes, ventilationRes, airConditioningRes]) => {
        const boilerRoomInventory = boilerRoomRes.data;
        const ventilationInventory = ventilationRes.data;
        const airConditioningInventory = airConditioningRes.data;
        return {
          boilerRoomInventory,
          ventilationInventory,
          airConditioningInventory
        };
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
  // getLocalInventory = (boilerRoomId) => {
  //   return this.localInventory.getItem((boilerRoomId).toString()).then(inventory => inventory);
  // };
  getLocalInventory = ({ boilerRoomId, ventilationId, airConditioningId }) => {
    const localInventoryPromises = [
      this.localInventory.getItem(boilerRoomId.toString()).then(inventory => inventory),
      this.localVentilation.getItem(ventilationId.toString()).then(inventory => inventory),
      this.localAirConditioning.getItem(airConditioningId.toString()).then(inventory => inventory)
    ];

    return Promise.all(localInventoryPromises);
  };
  // to delete
  cleanLocalInventories() {
    return this.localInventory.clear()
  }


  // POST MEDIA
  postMedia(media) {
    return new Promise((resolve, reject) => resolve(media));
  }


  async postMeta(meta) {
    return {}
    // if (navigator.onLine && !ls('push')) {
    //   meta.value = meta.label;
    //   let result = await axios.post('/metas', meta);
    //   let cleanResp = result.data.data;
    //   meta = _.omit(cleanResp, ['__v'])
    //   meta.id = meta._id
    // }
    // return this.localMetas.setItem(meta.id, meta).then(result => {
    //   return meta;
    // })
  };

  resetMedias() {
    return this.localMedia.clear();
  }

  resetMetas() {
    return this.localMetas.clear();
  }

  resetInventories() {
    return this.localInventory.clear();
  }

  resetDataBases() {
    return Promise.props({
      cleanInventory: this.localMedia.clear(),
      cleanMedias: this.localInventory.clear(),
      cleanMetas: this.localMetas.clear(),
      cleanMedia: localforage.clear(),
      cleanPush: ls('push', false)
    }).then(() => {
      return Promise.delay(100).then(() => {
        return true;
      });
    })
  }


}


export default new InventoryService();
