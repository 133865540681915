import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import RCUList from '../util/rcu.json';

class RcuSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.value ? props.value : null,
      options: null,
      inventory: props.inventory
    }
  }

  handleChange = event => {
    let value = event.target.value;
    this.setState({
      selected: value,
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.props.field, this.state.selected, this.props.index);
      }
    })
  };

  componentDidMount() {
    let options = [];
    if (this.props.zipcode) {
      let department = this.props.zipcode.substring(0, 2);
      options = RCUList
        .filter(rcu => ((parseInt(rcu.department, 10) === parseInt(department, 10)) || rcu.department === "*"))
        .map(item => item.networkName)
      this.setState({ options, selected: !this.state.selected ? options[0] : this.state.selected }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.props.field, this.state.selected, this.props.index);
        }
      })
    } else {
      options = RCUList
        .filter(rcu => (rcu.department === "*"))
        .map(item => item.networkName)

      this.setState({ options, selected: !this.state.selected ? options[0] : this.state.selected })
    }
    if (!this.props.value) {
      this.props.onChange(this.props.field, this.state.selected, this.props.index);
    }
  }


  render() {
    let { options } = this.state;
    let enumSelect = (options) ? (
      <section>
        <TextField
          disabled={(options.length <= 1)}
          select
          label={this.props.label}
          variant="outlined"
          autowidth="true"
          value={this.state.selected}
          onChange={this.handleChange}
          style={{ width: '100%', margin: '30px 0' }}
        >
          {
            options.map((option, index) => {
              return (
                <MenuItem key={index} value={option}>{this.props.t(option)}</MenuItem>
              )
            })
          }
        </TextField>


      </section>)
      : '';
    return (<div>{enumSelect}</div>)
  }
}

export default withRouter(withTranslation()(RcuSelect))
