import React from 'react';
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

class OneChoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: _.get(props, 'selected', null),
      options: _.get(props, 'options', null),
      inventory: props.inventory
    }
  }

  handleChange = event => {
    let value = _.get(event, 'target.value', null);
    this.setState({
      selected: value,
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.props.field, this.state.selected, this.props.index);
      }
    })
  };

  render() {
    let {options} = this.state;
    let enumSelect = (options) ? (
        <section className="one-choice">

          <label className="one-choice__label">
            {this.props.label}
          </label>

          <FormGroup style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '90%',
            margin: '0 auto'
          }}>
            {
              options.map((option, index) => {
                return (
                  <FormControlLabel
                    style={{
                      width: '33%'
                    }}
                    key={index}
                    control={
                      <Checkbox
                        checked={option === this.state.selected}
                        onChange={e => this.handleChange({target: {value: option}})}
                        value={option}
                      />
                    }
                    label={this.props.t(option)}
                  />
                )
              })
            }
          </FormGroup>

        </section>)
      : '';
    return (<div>{enumSelect}</div>)
  }
}

export default withRouter(withTranslation()(OneChoice))
