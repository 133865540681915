import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import Input from './Input';
import _ from "lodash";

class EnumSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.value ? props.value : props.options[0],
      options: props.options,
      [props.field + 'Other']: props.otherValue ? props.otherValue : null
    }
  }

  debounce = _.debounce((value) => {
    this.props.enumChanged(this.props.field, value, this.props.index);
    if (this.thereIsOther()) {
      this.props.otherChanged(this.props.field + 'Other', this.state[this.props.field + 'Other'] , this.props.index)
    }


  }, this.props.debounce !== undefined ? this.props.debounce : 200, {});


  handleChange = event => {
    let value = event.target.value;
    this.setState({
      selected: value,
      [this.props.field + 'Other']: value === 'other' ? '' : null
    }, () => {
      this.debounce(this.state.selected);
    })
  };

  componentDidMount() {
    if (!this.props.value) {
      this.props.enumChanged(this.props.field, this.state.selected, this.props.index);
      if (this.thereIsOther()) {
        this.props.otherChanged(this.props.field + 'Other', this.state[this.props.field + 'Other'] , this.props.index)
      }
    }
  }


  thereIsOther = () => {
    return this.state.options.indexOf('other') > -1
  };


  displayOther = () => {
    let {selected} = this.state;
    return (selected === 'other');
  };

  handleOtherInputChange = (field, value) => {
    this.setState({
      [field]: value
    }, () => {
      if (this.thereIsOther() && this.props.otherChanged) {
        this.props.otherChanged(field, value , this.props.index);
      }
    })
  };

  render() {
    let {options} = this.state;
    let enumSelect = (options) ? (
        <section>
          <TextField
            select
            label={this.props.label}
            variant="outlined"
            autowidth="true"
            value={this.state.selected}
            onChange={this.handleChange}
            style={{ width: '100%' }}
          >
            {
              options.map((option, index) => {
                return (
                  <MenuItem key={index} value={option}>{this.props.t(option)}</MenuItem>
                )
              })
            }
          </TextField>


          {
            this.displayOther() ? (

              <div
                style={{
                  margin: '30px 0'
                }}>

              <Input
                key={this.props.field + 'Other'}
                name={this.props.field + 'Other'}
                label={this.props.label + ' - Autre'}
                value={this.state[this.props.field + 'Other']}
                onChange={this.handleOtherInputChange}
              />
              </div>
            ) : ''
          }


        </section>)
      : '';
    return (<div>{enumSelect}</div>)
  }
}

export default withRouter(withTranslation()(EnumSelect))
