import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from "lodash";
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CustomedInput from './Input';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class EnumMultipleSelect extends React.Component {
  constructor(props) {


    super(props);
    this.state = {
      selected: props.value ? props.value : [],
      options: props.options,
      othersValue: props.othersValue ? props.othersValue : ['']
    }
  }

  debounce = _.debounce((value) => {
    this.props.enumChanged(this.props.field, value)
  }, this.props.debounce !== undefined ? this.props.debounce : 700, {});


  handleChange = event => {
    this.setState({
      selected: event.target.value,
      othersValue: this.thereIsOther() ? this.state.othersValue ? this.state.othersValue : [''] : null
    }, () => {
      this.debounce(this.state.selected);
    })
  };

  componentDidMount() {

    if (!this.props.value) {
      this.props.enumChanged(this.props.field, this.state.selected, this.props.index)
    }
  }


  getSelectValue = (selected) => {
    let label = '';
    let {options} = this.state;
    selected && selected.forEach((id, index2) => {
      options.forEach((option, index) => {
        if (option === id) {
          label += this.props.t(option);
        }
      });

      if (index2 < selected.length - 1) {
        label += ', ';
      }
    });
    return label
  };


  thereIsOther = () => {
    let {options} = this.state;
    return options.indexOf('other') > -1;
  };

  displayOther = () => {
    let {selected} = this.state;
    return selected.indexOf('other') > -1;
  };

  handleOthersValueChange = (field, value, index) => {
    let {othersValue} = this.state;
    othersValue[index] = value;
    this.setState({othersValue} , () => {
      this.props.otherChanged(field, this.state.othersValue, this.props.index);
    });
  };


  addOther = () => {
    let {othersValue} = this.state;
    othersValue.push('');
    this.setState({othersValue}, () => {
      this.props.otherChanged(this.props.field + 'Others', this.state.othersValue, this.props.index);
    });
  };


  deleteOther = (index) => {
    let {othersValue} = this.state;
    othersValue.splice(index, 1);
    this.setState({othersValue}, () => {
      this.props.otherChanged(this.props.field + 'Others', this.state.othersValue, this.props.index);
    });
  };

  render() {
    let {options} = this.state;
    let enumSelect = (options) ? (
        <section>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="select-multiple-checkbox">{this.props.label}</InputLabel>
            <Select
              multiple
              value={this.state.selected}
              onChange={this.handleChange}
              input={<Input id="select-multiple-checkbox"/>}
              renderValue={selected => this.getSelectValue(selected)}
              MenuProps={MenuProps}
            >
              {this.state.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  <Checkbox checked={this.state.selected.indexOf(option) > -1}/>
                  <ListItemText primary={this.props.t(option)}/>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {
            this.displayOther() ? (

              <div
                style={{
                  margin: '30px 0'
                }}>

                {
                  this.state.othersValue.map((other, index) => {
                    return (
                      <section>
                        <CustomedInput
                          index={index}
                          key={this.props.field + 'Others' + index}
                          name={this.props.field + 'Others'}
                          label={this.props.label + ' - Autre' + (index + 1)}
                          value={other}
                          onChange={this.handleOthersValueChange}
                        />
                        {
                          (this.state.othersValue.length > 1) ? (
                            <div style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: '20px 0'
                            }}>
                              <Tooltip title="Supprimer cette valeur " aria-label="Add"
                                       onClick={() => this.deleteOther(index)}>
                                <Fab color="secondary" aria-label="Delete" size={'small'}>
                                  <DeleteIcon/>
                                </Fab>
                              </Tooltip>
                            </div>
                          ) : ''
                        }


                        {
                          (index === this.state.othersValue.length - 1) ? (
                            <div style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginTop: '20px'
                            }}>
                              <Tooltip title="Ajouter une autre valeur" aria-label="Add" onClick={() => this.addOther()}>
                                <Fab color="primary" aria-label="Add" size={'small'}>
                                  <AddIcon/>
                                </Fab>
                              </Tooltip>
                            </div>) : ''
                        }
                      </section>
                    )
                  })
                }
              </div>
            ) : ''
          }


        </section>

      )
      : '';
    return (<div>{enumSelect}</div>)
  }
}

export default withRouter(withTranslation()(EnumMultipleSelect))
