import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Promise from "bluebird";
import CamelCase from 'camelcase';
import core from "core";
import ls from 'local-storage';
import localforage from 'localforage';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import LazyLoad from 'react-lazy-load';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import Actions from "../../Fields/Actions";
import MyDatePicker from "../../Fields/DatePicker";
import EnumMultipleSelect from "../../Fields/EnumMultipleSelect";
import Indication from "../../Fields/Indication";
import Input from "../../Fields/Input";
import OneChoice from "../../Fields/OneChoice";
import RcuSelect from "../../Fields/RcuSelect";
import SmokeDuctDiameter from "../../Fields/SmokeDuctDiameter";
import CustomizedSwitch from "../../Fields/Switch";
import Upload from "../../Fields/Upload";
import EnumSelect from '../../Fields/enumSelect';
import inventoryService from '../../Services/InventoryService';
import axios from "../../libs/axios";
import * as HeadersActions from "../../redux/actions/headers";
import * as InventoryActions from "../../redux/actions/inventory";
import * as NetworkActions from "../../redux/actions/network";
import * as UserActions from "../../redux/actions/user";
import populateData from '../../util/populate';
import Header from "../Header/HeaderComponent";
import SyncGeoComponent from '../geoLocalisation/SyncGeoComponent';

class InventoryEditComponent extends React.Component {
  constructor(props) {
    super(props);
    let populate = '';
    populateData.data.forEach((item, index) => {
      populate += item;
      if (index < populateData.data.length - 1) {
        populate += ',';
      }
    });


    this.state = {
      currentMenu: null,
      currentSubMenu: null,
      form: null,
      isLoading: false,
      zipcode: null,
      metas: props.metas,
      inventoryId: props.match.params.id,
      lazyLoadVerticalOffset: 1500,
      urlParams: {
        populate: populate,
        page: 1,
        limit: 20,
        technician: _.get(props, 'currentUser._id', null),
        stateOfProgress: 'programmed'
      }
    };
  }


  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (!this.state.allMetas) {
      Promise.props({
        getAllMetas: navigator.onLine && !ls('push') ? inventoryService.getRemoteMetas() : inventoryService.getLocalMetas()
      }).then(res => {
        this.setState({
          allMetas: res.getAllMetas
        })
      })
    }
  }


  componentDidMount() {
    let { inventoryId } = this.state;
    if (!inventoryId) {
      this.props.history.push('/');
    } else {
      let isOnline = navigator.onLine;
      this.setState({ technician: _.get(this.props, 'currentUser._id', false) }, () => {
        // GET DATA
        Promise.props({
          inventories: isOnline && !ls('push') ? inventoryService.getRemoteInventories(this.state.urlParams) : inventoryService.getLocalInventories(),
          metas: isOnline && !ls('push') ? inventoryService.getRemoteMetas() : inventoryService.getLocalMetas()
        }).then(resp => {
          if (isOnline && !ls('push')) {
            // CLEAN DB
            Promise.props({
              resetDataBase: inventoryService.resetDataBases()
            }).then(() => {
              this.setState({
                allMetas: resp.metas
              }, () => {
                // UPDATE DB
                Promise.props({
                  setLocalInventories: inventoryService.setLocalInventories(resp.inventories),
                  setLocalMetas: inventoryService.setLocalMetas(resp.metas),
                }).then(res => {
                  let exist = false;
                  resp.inventories.forEach(project => {
                    if (project.id === parseInt(inventoryId, 10)) {
                      exist = project
                      this.setState({ zipcode: project.zipcode ?? null })
                    }
                  });
                  if (exist) {
                    this.getPathsFromPathName(this.props.location.pathname, _.cloneDeep(exist), isOnline);
                  }
                })
              })
            })
          } else {
            this.setState({
              allMetas: resp.metas
            }, () => {
              let exist = false;
              resp.inventories.forEach(project => {
                if (project.id === parseInt(inventoryId, 10)) {
                  exist = project
                  this.setState({ zipcode: project.zipcode ?? null })
                }
              });
              if (exist) {
                this.getPathsFromPathName(this.props.location.pathname, _.cloneDeep(exist), isOnline);
              }
            })
          }
        })
      })
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!navigator.onLine) {
      if (this.props.network) {
        this.props.networkActions.setOffline(true);
        toast.warn("Le mode Offline est activé !");
      }
    } else {
      if (!this.props.network) {
        this.props.networkActions.setOnline(true);
        toast.success("Vous êtes connécté !");
      }
    }
  }


  initForm = (inventory, isOnline) => {
    if (!this.state.formFields) {
      let formFields = [];
      let populate = '';

      let { currentMenu, currentSubMenu } = this.state;

      Object.keys(core.files[currentMenu][currentSubMenu]).forEach(function (key) {
        formFields.push(key);
      });

      populateData.data.forEach((item, index) => {
        populate += item;
        if (index !== populateData.data.length - 1) {
          populate += ',';
        }
      });

      this.setState({
        populateMeta: populate
      }, () => {
        Promise.props({
          populate: isOnline && !ls('push') ? inventoryService.getRemoteInventory({ boilerRoomId: inventory.boilerRoomInventory.id, ventilationId: inventory.idVentilationInventory, airConditioningId: inventory.idAirConditioningInventory }) : inventoryService.getLocalInventory({ boilerRoomId: inventory.boilerRoomInventory.id, ventilationId: inventory.idVentilationInventory, airConditioningId: inventory.idAirConditioningInventory })
        }).then(res => {
          let populatedObject = null;
          if (isOnline && !ls('push')) {
            populatedObject = res.populate.data;
          } else {
            populatedObject = res.populate;
          }


          let populatedMeta = {};

          populateData.data.forEach(item => {
            if (_.isArray(_.get(populatedObject, item))) {
              let tmpArray = [];
              _.get(populatedObject, item).forEach(meta => {
                tmpArray.push(meta.label)
              });
              _.set(populatedMeta, item, tmpArray);

            } else {
              _.set(populatedMeta, item, _.get(populatedObject, item, null) ? (_.get(populatedObject, item).natureOfEcsProduction ? _.get(populatedObject, item) : _.get(populatedObject, item).label) : null);
            }

          });

          inventory = inventoryService.setEnergies(inventory);

          this.setState({ formFields, populatedMeta }, () => {

            if (!inventory[this.state.currentMenu]) {
              _.set(inventory, [this.state.currentMenu], {});
            }

            if (!inventory[this.state.currentMenu][this.state.currentSubMenu]) {
              if (_.get(core.files, this.state.currentMenu + '.' + this.state.currentSubMenu + '.loopForm.type', false) === '*') {
                _.set(inventory, [this.state.currentMenu, this.state.currentSubMenu], []);
              } else {
                _.set(inventory, [this.state.currentMenu, this.state.currentSubMenu], {});
              }
            }


            let currentPath = this.state.currentMenu + '.' + this.state.currentSubMenu;


            if (_.get(core.files, currentPath + '.loopForm.dependsOn', false)) {
              let nbElements = 0;
              if (_.get(core.files, _.get(core.files, currentPath + '.loopForm.dependsOn') + '.loopForm')) {
                nbElements = _.get(inventory, _.get(core.files, currentPath + '.loopForm.dependsOn'), []).length;
              } else {
                nbElements = _.get(inventory, _.get(core.files, currentPath + '.loopForm.dependsOn'), 0);
              }
              if (nbElements > 0) {
                if (_.get(inventory, currentPath, []).length === 0) {
                  let tmpArray = [];
                  for (let i = 0; i < nbElements; i++) {
                    tmpArray.push({});
                  }
                  _.set(inventory, currentPath, tmpArray);
                } else if (_.get(inventory, currentPath, []).length < nbElements) {
                  for (let i = _.get(inventory, currentPath, []).length; i < nbElements; i++) {
                    inventory[currentMenu][currentSubMenu].push({});
                  }
                } else if (_.get(inventory, currentPath, []).length > nbElements) {
                  let nbElemToDelete = _.get(inventory, currentPath, []).length - nbElements;
                  for (let i = 0; i < nbElemToDelete; i++) {
                    _.get(inventory, currentPath, []).splice(-1, 1)
                  }
                }
              } else {
                _.set(inventory, currentPath, null);
              }


            }

            let formTitle = null;


            let menus = core.menuGenerator(inventory, this.state.allMetas, inventory.interventionType === 'completeSupport' ? 'complete' : 'shortened');

            Object.keys(menus).forEach(menu => {
              if (menu === this.state.currentMenu) {
                Object.keys(_.get(menus, menu + '.subMenus')).forEach(subMenu => {
                  if (subMenu === this.state.currentSubMenu) {
                    formTitle = _.get(menus, [menu, 'subMenus', subMenu, 'label'], '');
                  }
                })
              }
            });
            this.setState({ inventory, originInventory: _.cloneDeep(inventory), formTitle });
          });
        })
      });
    }
  };


  getPathsFromPathName = (pathname, inventory, isOnline) => {
    if (inventory.generalInformations &&
      inventory.generalInformations.visitInformations &&
      inventory.generalInformations.visitInformations.GPScoordinates &&
      inventory.generalInformations.visitInformations.GPScoordinates.coordinate &&
      inventory.generalInformations.visitInformations.GPScoordinates.coordinate.length > 0
    ) {
      inventory.generalInformations.visitInformations.latitude = inventory.generalInformations.visitInformations.GPScoordinates.coordinate[0];
      inventory.generalInformations.visitInformations.longitude = inventory.generalInformations.visitInformations.GPScoordinates.coordinate[1];
    }

    let menuUrl = pathname.split('/')[1];
    let subMenuUrl = pathname.split('/')[2];
    this.setState({
      currentMenu: CamelCase(menuUrl),
      currentSubMenu: CamelCase(subMenuUrl)
    }, () => {
      this.initForm(inventory, isOnline);
    })
  };


  handleClassicChange = (path, value) => {
    let { inventory } = this.state;
    _.set(inventory, path, value);
    this.setState({ inventory });
  };


  handleGeoChange = (lat, long) => {
    let { extraInfos, currentMenu, currentSubMenu, inventory } = this.state;
    _.set(inventory, [currentMenu, currentSubMenu, 'latitude'], lat);
    _.set(inventory, [currentMenu, currentSubMenu, 'longitude'], long);
    _.set(extraInfos, ['GPScoordinates', 'latitude'], lat);
    _.set(extraInfos, ['GPScoordinates', 'longitude'], long);
    this.setState({ extraInfos, inventory });
  };


  postMedia = (object) => {
    let formData = new FormData();
    if (navigator.onLine && !ls('push')) {
      formData.append('media', new File([_.get(object, ['file'])], _.get(object, ['name']), {
        type: _.get(object, ['type']),
        lastModified: Date.now()
      }));
      return axios.post('/media', formData);
    } else {
      // Offline
      return localforage.setItem(_.get(object, 'id'), _.get(object, 'file')).then(image => {
        return {
          name: object.name,
          id: object.id,
          file: window.URL.createObjectURL(image),
          type: object.type
        }
      })
    }
  };


  prepareFields = (object, path, descFile = _.cloneDeep(core.files)) => {
    let realPath = path.replace(/[0-9]/g, '').replace('[', '').replace(']', '');

    while (realPath.includes('[') || realPath.includes(']')) {
      realPath = realPath.replace('[', '');
      realPath = realPath.replace(']', '');
    }

    if (_.get(descFile, realPath + '.type', '') === '*') {
      _.set(descFile, realPath, _.get(descFile, realPath + '.fields'));
    }


    if (_.get(descFile, realPath + '.loopForm.type', '') === '*') {
      _.set(descFile, realPath, _.get(descFile, realPath + '.loopForm.fields'));
    }


    let result = {
      postMedias: {}
    };
    Object.keys(object).forEach((key, index) => {
      // loopForm field --> recursive
      if (object[key] &&
        typeof object[key] !== 'string' &&
        object[key].length && object[key].length > 0 &&
        (_.get(descFile, realPath + '.' + key + '.type', false) === '*')
        &&
        !(_.get(descFile, realPath + '.' + key + '.fks', false) === true)

      ) {
        object[key].forEach((item, index) => {
          let tmpResultPromises = this.prepareFields(item, path + '.' + key + '[' + index + ']', descFile).postMedias;
          Object.keys(tmpResultPromises).forEach((key, index) => {
            result.postMedias[key] = tmpResultPromises[key];
          });
          tmpResultPromises = null;
        });
      } else {
        // Unchanged fields
        if (_.get(object, key + '._id', false) && navigator.onLine) {
          if (!ls('push')) {
            _.set(object, key, _.get(object, key + '._id'));
          }
        }

        // Date inputs
        if (_.get(descFile, realPath + '.' + key + '.type', false) === 'date') {
          _.set(object, key, moment(_.get(object, key)).format())
        }

        // One media
        if (_.get(descFile, realPath + '.' + key + '.type', false) === 'upload' && !_.get(descFile, realPath + '.' + key + '.fks', false)) {
          if (!_.get(object, key)) {
            _.set(object, key, null);
          } else {
            if (!(typeof _.get(object, key) === 'string') && !_.get(object, key + '._id') && _.get(object, key + '.file') && (_.get(object, key + '.file') instanceof Blob)) {
              result.postMedias[path + '.' + key] = this.postMedia(_.get(object, [key]), path + '.' + key);
            }
          }
        }

        // lot of medias
        // if (_.get(descFile, realPath + '.' + key + '.type', false) === 'upload' && _.get(descFile, realPath + '.' + key + '.fks', false)) {
        //   if (!_.get(object, key)) {
        //     object[key] = null;
        //   } else {
        //     let unchangedMedia = [];
        //     object[key].forEach((media, index) => {
        //       // Remote media
        //       if (_.get(media, '_id')) {
        //         if (navigator.onLine && !ls('push')) {
        //           unchangedMedia.push(_.get(media, '_id'));
        //         } else {
        //           unchangedMedia.push(media);
        //         }
        //       } else {
        //         // Local media
        //         if (_.get(media, 'file') instanceof Blob) {
        //           // New media
        //           result.postMedias[path + '.' + key + '*' + index] = this.postMedia(media, path + '.' + key);
        //         } else {
        //           // Still in local
        //           if (!navigator.onLine || (navigator.onLine && ls('push'))) {
        //             unchangedMedia.push(media);
        //           }
        //         }
        //       }
        //     });
        //     object[key] = unchangedMedia;
        //   }
        // }

      }
    });
    return result;
  };


  save = () => {
    this.setState({
      isLoading: true
    }, () => {
      let isOnline = navigator.onLine;
      // INIT
      let { currentMenu, currentSubMenu, inventory } = this.state;
      if (_.get(inventory, 'generalInformations.visitInformations', false)) {
        if (_.get(inventory, 'generalInformations.visitInformations.isEcsProdCoupled', false) !== 'separated') {
          _.set(inventory, 'generalInformations.visitInformations.heatingType', null);
          _.set(inventory, 'generalInformations.visitInformations.ecsType', null);
        } else {
          _.set(inventory, 'generalInformations.visitInformations.energyType', null);

        }
      }
      let reqBody = _.get(inventory, [currentMenu, currentSubMenu]);
      let currentPath = currentMenu + '.' + currentSubMenu;


      let preparedFields = [];
      if (reqBody.length && reqBody.length > 0) {
        reqBody.forEach((item, index) => {
          preparedFields.push(this.prepareFields(item, currentPath + '[' + index + ']'))
        })
      } else {
        preparedFields.push(this.prepareFields(reqBody, currentPath))
      }

      let extractPromises = {};

      preparedFields.forEach(item => {
        Object.keys(item.postMedias).forEach(key => {
          extractPromises[key] = item.postMedias[key];
        })
      });
      preparedFields = null;


      Promise.props(extractPromises).then(postMediasResponse => {
        Object.keys(postMediasResponse).forEach(key => {
          // Array of medias
          if (key.includes('*')) {
            let realKey = key.split('*')[0];
            if (!reqBody[reqBody.length > 0 ? realKey.replace(currentPath, '') : realKey.replace(currentPath + '.', '')]) {
              reqBody[reqBody.length > 0 ? realKey.replace(currentPath, '') : realKey.replace(currentPath + '.', '')] = [];
            }
            if (navigator.onLine && !ls('push')) {
              reqBody[reqBody.length > 0 ? realKey.replace(currentPath, '') : realKey.replace(currentPath + '.', '')].push(_.get(postMediasResponse, [key, 'data', 'data', '_id'], _.get(postMediasResponse, [key, 'data', 'data', '_id'])));
            } else {
              let localImg = {
                name: _.get(postMediasResponse[key], 'name'),
                id: _.get(postMediasResponse[key], 'id'),
                file: _.get(postMediasResponse[key], 'file'),
                type: _.get(postMediasResponse[key], 'type')
              };
              reqBody[reqBody.length > 0 ? realKey.replace(currentPath, '') : realKey.replace(currentPath + '.', '')].push(localImg);
            }
          } else { // one media
            if (navigator.onLine && !ls('push')) {
              _.set(reqBody, reqBody.length ? key.replace(currentPath, '') : key.replace(currentPath + '.', ''), _.get(postMediasResponse, [key, 'data', 'id']))
            } else {
              let localImg = {
                name: _.get(postMediasResponse[key], 'name'),
                id: _.get(postMediasResponse[key], 'id'),
                file: _.get(postMediasResponse[key], 'file'),
                type: _.get(postMediasResponse[key], 'type')
              };
              // because of x.y[0].z , x.y.z
              _.set(reqBody, reqBody.length ? key.replace(currentPath, '') : key.replace(currentPath + '.', ''), localImg);
            }
          }
        });
        const { idVentilationInventory, ventilationInventory, locationOne, locationTwo, locationThree, locationFour, locationFive, locationSix, locationSeven, locationEight, idAirConditioningInventory, airConditioningInventory, airConditioningLocationOne, airConditioningLocationTwo, airConditioningLocationThree, airConditioningLocationFour, airConditioningLocationFive, airConditioningLocationSix, airConditioningLocationSeven, airConditioningLocationEight, ...boilerRoomInventory } = inventory
        let modifiedInventory = boilerRoomInventory;
        let modifiedVentilation = { ventilationInventory, locationOne, locationTwo, locationThree, locationFour, locationFive, locationSix, locationSeven, locationEight, id: idVentilationInventory }
        let modifiedAirConditioning = {airConditioningInventory, airConditioningLocationOne, airConditioningLocationTwo, airConditioningLocationThree, airConditioningLocationFour, airConditioningLocationFive, airConditioningLocationSix, airConditioningLocationSeven, airConditioningLocationEight, id:idAirConditioningInventory }
        _.set(modifiedInventory, currentPath, _.cloneDeep(reqBody));
        if (!inventory.checkedSections) {
          inventory.checkedSections = []
        }

        if (inventory.checkedSections.indexOf(currentPath) < 0) {
          inventory.checkedSections.push(currentPath);
        }

        let divUrl = this.props.history.location.pathname.split('/');
        if (isOnline && !ls('push')) {
          modifiedInventory.checkedSections = inventory.checkedSections;
          modifiedInventory.id = inventory.id;
          modifiedInventory.project = inventory.project;
          Promise.props({
            putInventory: axios.put('/boiler-room-inventory/' + inventory.id, modifiedInventory),
            putVentilation: axios.put('/ventilation-inventory/' + inventory.idVentilationInventory, modifiedVentilation),
            putAirConditioning: axios.put('/airConditioning-inventory/' + inventory.idAirConditioningInventory, modifiedAirConditioning),
          }).then((resp) => {
            toast.success('Enregistré avec succès !');
            this.props.history.push('/menu/' + inventory.id + '#' + divUrl[1] + '_' + divUrl[2]);
          });
        } else {
          inventoryService.getLocalInventory({ boilerRoomId: inventory.boilerRoomInventory.id, ventilationId: inventory.idVentilationInventory, airConditioningId: inventory.idAirConditioningInventory }).then((oldInventory) => {
            _.set(inventory, currentPath, reqBody);
            _.set(inventory, 'offlineModified', true);
            _.set(inventory, 'project', inventory.project);
            if (!inventory.offlineModifiedSection) {
              inventory.offlineModifiedSection = [];
            }
            if (!inventory.offlineModifiedSection.includes(currentMenu + '.' + currentSubMenu)) {
              inventory.offlineModifiedSection.push(currentMenu + '.' + currentSubMenu);
            }
            oldInventory = {
              ...oldInventory,
              ...inventory
            }
            inventoryService.setLocalInventory(oldInventory).then(res => {
              ls('push', true);
              toast.success('Enregistré en local avec succès !');
              this.props.history.push('/menu/' + inventory.id + '#' + divUrl[1] + '_' + divUrl[2]);
            })
          })

        }
      })

    })

  };


  refresh = (path) => {
    this.setState({ refresh: true })
  }

  addItem = (path) => {
    let { inventory } = this.state;
    if (!_.get(inventory, path, false) || !_.isArray(_.get(inventory, path))) {
      _.set(inventory, path, [{}])
    } else {
      let tmp = _.cloneDeep(_.get(inventory, path));
      _.set(inventory, path, null);
      tmp.push({});
      _.set(inventory, path, tmp);
    }
    this.setState({ inventory })
  };

  deleteItem = (path, index) => {
    let { inventory } = this.state;
    let tmp = _.cloneDeep(_.get(inventory, path));
    tmp.splice(index, 1);
    this.setState({ inventory: null }, () => {
      _.set(inventory, path, _.cloneDeep(tmp));
      this.setState({ inventory })
    })
  };

  render() {
    let { currentMenu, currentSubMenu, inventory, formTitle } = this.state;
    if (window.tidioChatApi) {
      window.tidioChatApi.hide()
    }
    // inventory = inventory?.boilerRoomInventory
    const ACCEPTED_KEYS_INVENTORY = ['all']
    if (_.get(inventory, 'interventionType', 'completeSupport') === 'completeSupport') {
      ACCEPTED_KEYS_INVENTORY.push('complete')
    } else {
      ACCEPTED_KEYS_INVENTORY.push('shortened')
    }
    let browseFields = (obj, path, fieldPresence) => {
      return Object.keys(obj).map((key, index) => {
        if (((_.get(fieldPresence, key, false) === true && ACCEPTED_KEYS_INVENTORY.includes(_.get(obj, [key, 'inventoryVersion'], ''))) || _.get(fieldPresence, [key, 'type'], false) === '*') && !_.get(obj, [key, 'virtual']) && _.get(obj, [key, 'front']) !== false) {
          if (_.get(obj, [key, 'type']) === 'text' ||
            _.get(obj, [key, 'type']) === 'email' ||
            _.get(obj, [key, 'type']) === 'number' ||
            _.get(obj, [key, 'type']) === 'tel' ||
            _.get(obj, [key, 'type']) === 'password' ||
            _.get(obj, [key, 'type']) === 'url') {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <Input
                  label={_.get(obj, [key, 'label'])}
                  name={path + '.' + key}
                  type={_.get(obj, [key, 'type'])}
                  value={_.get(inventory, path + '.' + key, null)}
                  onChange={this.handleClassicChange}
                  disabled={_.get(obj, [key, 'disabled'])}
                  multiline={_.get(obj, [key, 'multiline'])}
                  min={_.get(obj, [key, 'min'])}
                />
              </LazyLoad>
            )
          } else if (_.get(obj, [key, 'type']) === 'title') {
            return (
              <div
                key={path + '.' + key}
                style={{ flex: '1 1 100%' }}>
                <LazyLoad
                  offsetVertical={this.state.lazyLoadVerticalOffset}
                >
                  <h2 className={'h2'}>{_.get(obj, [key, 'label'])}</h2>
                </LazyLoad>
              </div>
            )
          } else if (_.get(obj, [key, 'type']) === 'indication') {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <Indication
                  target={_.get(core.files, _.get(obj, [key, 'target']))}
                  value={_.get(inventory, _.get(obj, [key, 'target']), "n'est pas renseigné")}
                />
              </LazyLoad>
            )
          } else if (_.get(obj, [key, 'type']) === 'oneChoice') {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <OneChoice
                  currentMenu={currentMenu}
                  currentSubMenu={currentSubMenu}
                  options={_.get(obj, [key, 'options'], null)}
                  field={path + '.' + key}
                  label={_.get(obj, [key, 'label'])}
                  selected={_.get(obj, [key, 'external'], false) ? _.get(inventory, [key, '_id']) : _.get(inventory, path + '.' + key)}
                  onChange={this.handleClassicChange}
                />
              </LazyLoad>
            )
            // } else if (_.get(obj, [key, 'type']) === 'metaSelect') {
            //   return (
            //     <LazyLoad
            //       offsetVertical={this.state.lazyLoadVerticalOffset}
            //       key={path + '.' + key}
            //     >
            //       <MetaSelect
            //         currentMenu={currentMenu}
            //         currentSubMenu={currentSubMenu}
            //         field={path + '.' + key}
            //         label={_.get(obj, [key, 'label'])}
            //         metaChanged={this.handleClassicChange}
            //         metaName={_.get(obj, [key, 'metaName'])}
            //         selected={_.get(obj, [key, 'external'], false) ? _.get(inventory, [key, '_id']) : _.get(inventory, path + '.' + key)}
            //         manageable={_.get(obj, [key, 'manageable'])}
            //       />
            //     </LazyLoad>
            //   )
          } else if (_.get(obj, [key, 'type']) === 'smokeDuctDiameter') {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <SmokeDuctDiameter
                  currentMenu={currentMenu}
                  currentSubMenu={currentSubMenu}
                  field={path + '.' + key}
                  label={_.get(obj, [key, 'label'])}
                  diametersChanged={this.handleClassicChange}
                  diameters={_.get(inventory, path + '.' + key)}
                  manageable={_.get(obj, [key, 'manageable'])}

                />
              </LazyLoad>
            )
          } else if (_.get(obj, [key, 'type']) === 'switch') {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <CustomizedSwitch
                  name={path + '.' + key}
                  label={_.get(obj, [key, 'label'])}
                  onChange={this.handleClassicChange}
                  checked={_.get(inventory, path + '.' + key, false)}
                  if={_.get(inventory, path + '.' + key + '.if', null)}
                />
              </LazyLoad>
            )
          } else if (_.get(obj, [key, 'type']) === 'upload'
            && (_.get(obj, [key, 'path']) === 'generalInformations.visitInformations.buildingPic'
              || _.get(obj, [key, 'path']) === 'generators.generatorsOverview.generatorPicture'
              || _.get(obj, [key, 'path']) === 'boilerRoom.boilerState.principalSchemaPic'
              || _.get(obj, [key, 'path']).includes('commentsAndAnnex.pictures'))
          ) {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <Upload
                  files={_.get(inventory, path + '.' + key, [])}
                  file={!_.get(obj, [key, 'fks'], false) ? _.get(inventory, path + '.' + key, null) : null}
                  id={path + '.' + key}
                  name={path + '.' + key}
                  label={_.get(obj, [key, 'label'])}
                  multiple={_.get(obj, [key, 'multiple'])}
                  highQuality={_.get(obj, [key, 'highQuality'], false)}
                  accept={_.get(obj, [key, 'accept'])}
                  filesChanged={this.handleClassicChange}
                  fileChanged={this.handleClassicChange}
                  inventoryId={inventory.id}
                />
              </LazyLoad>
            )
          } else if (_.get(obj, [key, 'type']) === 'date') {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <MyDatePicker
                  label={_.get(obj, [key, 'label'])}
                  name={path + '.' + key}
                  onChange={this.handleClassicChange}
                  value={_.get(inventory, path + '.' + key, moment())}
                />
              </LazyLoad>
            )
            // } else if (_.get(obj, [key, 'type']) === 'metaMultipleSelect') {
            //   return (
            //     <LazyLoad
            //       offsetVertical={this.state.lazyLoadVerticalOffset}
            //       key={path + '.' + key}
            //     >
            //       <MetaSelectMultiple
            //         currentMenu={currentMenu}
            //         currentSubMenu={currentSubMenu}
            //         field={path + '.' + key}
            //         label={_.get(obj, [key, 'label'])}
            //         metaMultipleChanged={this.handleClassicChange}
            //         metaName={_.get(obj, [key, 'metaName'])}
            //         manageable={_.get(obj, [key, 'manageable'])}
            //         selected={_.get(obj, [key, 'external']) ? _.get(inventory, [key]) : _.get(inventory, path + '.' + key)}
            //       />
            //     </LazyLoad>
            //   )
          } else if (_.get(obj, [key, 'type']) === 'enum') {
            return (
              <LazyLoad
                offsetVertical={this.state.lazyLoadVerticalOffset}
                key={path + '.' + key}
              >
                <EnumSelect
                  currentMenu={currentMenu}
                  currentSubMenu={currentSubMenu}
                  enumChanged={this.handleClassicChange}
                  otherChanged={this.handleClassicChange}
                  otherValue={_.get(inventory, currentPath + '.' + key + 'Other', null)}
                  field={path + '.' + key}
                  label={_.get(obj, [key, 'label'])}
                  value={_.get(inventory, path + '.' + key, null)}
                  options={_.get(obj, [key, 'options'])}

                />
              </LazyLoad>
            )
          } else if (_.get(obj, [key, 'type']) === 'multipleEnum') {
            return (
              (
                <LazyLoad
                  offsetVertical={this.state.lazyLoadVerticalOffset}
                  key={path + '.' + key}
                >
                  <EnumMultipleSelect
                    currentMenu={currentMenu}
                    currentSubMenu={currentSubMenu}
                    enumChanged={this.handleClassicChange}
                    field={path + '.' + key}
                    label={_.get(obj, [key, 'label'])}
                    value={_.get(inventory, path + '.' + key, null)}
                    otherChanged={this.handleClassicChange}
                    othersValue={_.get(inventory, currentPath + '.' + key + 'Others', null)}
                    options={_.get(obj, [key, 'options'])}
                  />
                </LazyLoad>
              )
            )
          } else if (_.get(obj, [key, 'type']) === 'rcuSelect') {
            return (
              (
                <LazyLoad
                  offsetVertical={this.state.lazyLoadVerticalOffset}
                  key={path + '.' + key}
                >
                  <RcuSelect
                    currentMenu={currentMenu}
                    currentSubMenu={currentSubMenu}
                    zipcode={this.state.zipcode}
                    onChange={this.handleClassicChange}
                    field={path + '.' + key}
                    label={_.get(obj, [key, 'label'])}
                    value={_.get(inventory, currentPath + '.' + key, null)}
                    inventory={this.state.inventory}
                  />
                </LazyLoad>
              )
            )
          } else if (_.get(obj, [key, 'type']) === 'syncGeo') {
            return (
              (
                <LazyLoad
                  offsetVertical={this.state.lazyLoadVerticalOffset}
                  key={path + '.' + key}
                >
                  <SyncGeoComponent
                    currentMenu={currentMenu}
                    currentSubMenu={currentSubMenu}
                    geoSynchronized={this.handleGeoChange}
                    label={_.get(obj, [key, 'label'])}
                    title={_.get(obj, [key, 'title'])}
                  />
                </LazyLoad>
              )
            )
          } else if (_.get(obj, [key, 'type']) === 'endOfSection') {
            return (
              (
                <LazyLoad
                  offsetVertical={this.state.lazyLoadVerticalOffset}
                  key={path + '.' + key}
                >
                  <div style={{
                    width: "100%",
                    border: '.5px solid'
                  }}></div>
                </LazyLoad>
              )
            )
          } else if (_.get(obj, [key, 'type']) === '*') {
            let display = false;

            if (_.get(obj, [key, 'dependsOn'], false)) {
              let nbItems = _.get(inventory, _.get(obj, [key, 'dependsOn']), 0);
              let currentNbItems = _.get(inventory, path + '.' + key, []).length;


              let nbItemsToAdd = nbItems - _.get(inventory, path + '.' + key, []).length;
              if (nbItems !== 0) {
                if (nbItemsToAdd > 0) {
                  let tmpItems = _.get(inventory, path + '.' + key, []);
                  while (tmpItems.length < nbItemsToAdd + currentNbItems) {
                    tmpItems.push({})
                  }
                  _.set(inventory, path + '.' + key, tmpItems);
                  this.refresh()
                } else if (nbItemsToAdd < 0) {
                  let tmpItems = [];
                  for (let i = 0; i < nbItems; i++) {
                    tmpItems.push(_.get(inventory, path + '.' + key, [])[i]);
                  }
                  _.set(inventory, path + '.' + key, tmpItems);
                  this.refresh();
                } else {
                  display = true
                }
              } else {
                display = true
              }

            } else {
              display = true
            }

            if (!!display) {
              return (
                <section
                  key={path + '.' + index}
                  className="form-loop-container"
                >
                  {
                    _.get(inventory, path + '.' + key, []).map((item, index) => {
                      return (
                        <div className="form-loop-container__item" key={index}>
                          <h1 style={{
                            textAlign: "center",
                            fontSize: "1.2em"
                          }}> {_.get(obj, [key, 'title'])} {(index + 1)}</h1>
                          {browseFields(obj[key].fields, path + '.' + key + '[' + index + ']', fieldPresence[key].fields[index])}

                          {_.get(obj, [key, 'manageable'], false) === true ? (
                            <div className="align-center">
                              <Tooltip
                                title="Supprimer"
                                aria-label="Delete"
                                onClick={() => this.deleteItem(path + '.' + key, index)}
                              >
                                <Fab color="secondary" aria-label="Delete" size={'small'}>
                                  <DeleteIcon />
                                </Fab>
                              </Tooltip>
                            </div>
                          ) : null}
                        </div>
                      )
                    })

                  }

                  {_.get(obj, [key, 'manageable'], false) === true ? (
                    <div className="form-loop-container__item align-center">
                      <Tooltip title="Ajouter" aria-label="Add" onClick={(e) =>
                        this.addItem(path + '.' + key)}>
                        <Fab color="primary" aria-label="Add" size={'small'}>
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  ) : null}

                </section>
              )
            } else {
              return ''
            }


          } else {
            return ''
          }
        } else {
          return ''
        }
      });
    };


    let fieldPresence = null
    let currentPath = null;
    if (inventory && currentMenu && currentSubMenu) {
      currentPath = currentMenu + '.' + currentSubMenu;
      // if (this.state.allMetas) {
      fieldPresence = core.coreMain(this.state.inventory, currentPath, this.state.allMetas);
      // } else {
      //   fieldPresence = null
      // }
    }


    let newFormSection = (fieldPresence) ? (
      <div className={'component-container-body'}>
        {
          (_.get(core.files, currentPath + '.loopForm.type') === '*') ? (
            <section>
              {
                _.get(inventory, currentPath, []).map((item, index) => {
                  return (
                    <section className="form-container" key={index}>
                      <h2
                        className="h2"
                        style={{
                          textAlign: 'center'
                        }}>{_.get(core.files, currentPath + '.loopForm.title') + ' ' + (index + 1)}</h2>
                      {
                        browseFields(_.get(core.files, currentPath + '.loopForm.fields'), currentPath + '[' + index + ']', fieldPresence.fields[index])
                      }

                      {
                        _.get(core.files, currentPath + '.loopForm.manageable', false) === true ? (
                          <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '20px'
                          }}>
                            <Tooltip title="Supprimer" aria-label="Delete"
                              onClick={() => this.deleteItem(currentPath, index)}>
                              <Fab color="secondary" aria-label="Delete" size={'small'}>
                                <DeleteIcon />
                              </Fab>
                            </Tooltip>
                          </div>
                        ) : ''
                      }

                    </section>
                  )
                })
              }


              {
                _.get(core.files, currentPath + '.loopForm.manageable', false) === true ? (
                  <div style={{
                    margin: '0 auto',
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px'
                  }}>
                    <Tooltip title="Ajouter" aria-label="Add" onClick={(e) =>
                      this.addItem(currentPath)}>
                      <Fab color="primary" aria-label="Add" size={'small'}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                ) : ''
              }


            </section>
          ) : (
            <section className="form-container">
              {browseFields(_.get(core.files, currentPath), currentPath, fieldPresence)}
            </section>
          )
        }
      </div>
    ) : ''


    let spinnerSection = (!inventory || !fieldPresence) ? (
      <section className={'spinner-container'}>
        <CircularProgress
          size={70}
          color={'secondary'}
        />
      </section>
    ) : '';

    return (
      <section className={'component-container'}>
        <Header title={formTitle ? formTitle : ''} />
        {newFormSection}
        <Actions
          updateForm={this.save}
          isLoading={this.state.isLoading}
        />
        {spinnerSection}

      </section>
    );
  }
}


const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  refreshToken: state.headers.refreshToken,
  currentUser: state.user.user,
  backUrl: state.constants.backUrl,
  inventory: state.inventory.inventory,
  network: state.network.network
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch),
  networkActions: bindActionCreators(NetworkActions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryEditComponent));
