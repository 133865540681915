import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from "lodash";

class CustomizedSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked ? props.checked : false,
      if:props.if ? props.if : null
    };
  }

  componentDidMount() {
    this.props.onChange(this.props.name, this.state['checked'],this.props.index);
  }


  debounce = _.debounce((value) => {
    this.props.onChange(this.props.name, value)
  }, this.props.debounce !== undefined ? this.props.debounce : 100, {});

  handleChange = e => {
    this.setState({
      checked: !this.state.checked
    }, () => {
      this.debounce(this.state.checked)
    });
  };

  render() {
    return (
      <section>
       <span>{this.props.label}</span>
        <div style={{
          fontSize:'.8em',
          fontWeight: 'bold',
          color:'#636e72'
        }}>
          <span>non</span>
          <FormControlLabel
            style={{
              marginLeft: 0,
              marginRight: 0,
            }}
            control={
              <Switch
                name={this.props.name}
                checked={this.state.checked}
                onChange={this.handleChange}
                value={this.state.checked}
                color="primary"
              />
            }
            label={''}
          />
          <span>oui</span>
        </div>
      </section>
    );
  }
}

export default CustomizedSwitch;
