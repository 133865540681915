import _ from "lodash";
import core from 'core'


function prepareData(object, path, descFile = core.files) {
  Object.keys(_.omit(object, ['_id'])).forEach(key => {
    // Simple field
    if (_.get(_.omit(object, ['_id']), [key, '_id'], false)) {
      _.set(object, key, _.get(object, [key, '_id']))
    }
// array field -- foreignKeys --recursive
    if (object[key] && object[key].length && object[key].length > 0 && typeof object[key] !== 'string') {
      if (_.get(descFile, path + '.' + key + '.fks', false) === true) {
        let tmpArray = []
        object[key].forEach(item => {
          tmpArray.push(item._id ? item._id : item);
        });
        object[key] = tmpArray;
      } else {
        if (_.get(descFile, path + '.' + key + '.type', false) === '*') {
          object[key].forEach(item => {
            let tmpDescFile = _.cloneDeep(descFile);
            _.set(tmpDescFile, path +'.'+ key, null);
            _.set(tmpDescFile, path +'.'+ key, _.get(descFile, path +'.' + key + '.fields'));
            prepareData(item, path + '.' + key, tmpDescFile)
          })
        }
      }
    }
  });
}


export default prepareData
