import React, { Component } from 'react';

import { withRouter } from "react-router";

import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import ReplyIcon from '@material-ui/icons/Reply';
import SaveIcon from '@material-ui/icons/Save';
import { connect } from "react-redux";
import { toast } from "react-toastify";


class Actions extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isLoading: props.isLoading ? props.isLoading : false
    };
    this.handleBackBtnClick = this.handleBackBtnClick.bind(this);
  }


  UNSAFE_componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    if (nextProps.isLoading && nextProps.isLoading !== this.state.isLoading) {
      this.setState({
        isLoading: nextProps.isLoading
      })
    }
  }

  handleBackBtnClick() {
    if (this.props.history.location.pathname.includes('menu')) {
      this.props.history.push('/')
    } else {
      let divUrl = this.props.history.location.pathname.split('/');
      this.props.history.push('/menu/' + divUrl[3] + '#' + divUrl[1] + '_' + divUrl[2])
    }
  }


  logOut = () => {
    this.props.history.push('/login');
    toast.success("à bientot !");
  };

  render() {

    let {isLoading} = this.state;
    let actionSection = (isLoading) ? (
      <CircularProgress/>
    ) : (
      <div className={'actions-container'} style={{display:'flex', justifyContent:'space-between', marginLeft:"30px",  marginRight:"30px", alignItems:'center', height: '8px'}}>
        { 
          this.props.match.params.id ? (
            <Tooltip
              title="Retour"
              placement="left"
              style={{
              }}
            >
              <Fab
                color={'secondary'}
                size="medium"
                aria-label="Retour"
                onClick={this.handleBackBtnClick}
              >
                <ReplyIcon/>
              </Fab>
            </Tooltip>

          ) : ''
        }

        {
          (this.props.updateForm) ? (
            <Tooltip
              title="Enregistrer"
              placement="left"
              style={{
                color:'white',
              }}
            >
              <Fab
                color="primary"
                size="medium"
                aria-label="Enregistrer"
                onClick={this.props.updateForm}
              >
                <SaveIcon/>
              </Fab>
            </Tooltip>
          ) : ''
        }


      </div>
    );

    return (
      <div style={{
        width: '100%',
        height: '8px',
        position: 'fixed',
        right: '5px',
        bottom: '50px',
      }}>

        {actionSection}
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Actions));
