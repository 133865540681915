import axios from 'axios'
import Promise from 'bluebird'
import cookie from 'js-cookie'
import _ from 'lodash'
import { toast } from 'react-toastify'
import inventoryService from './../Services/InventoryService'

async function urgencyMode(offlineInventories) {
  let user = JSON.parse(cookie.get('currentUser'))
  return Promise.map(offlineInventories, async (inventory) => {
    return instance.post('/backup', {
      inventory: JSON.stringify(inventory),
      email: user.email,
      userId: user._id,
    })
  }).then((r) => {
    toast.warn('Erreur, données sauvegardées en Backup')
  })
    .catch((err) => {
      toast.error('Erreur, service Backup indisponible')
      throw err
    })

}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL ?? 'http://localhost:8182',
  crossdomain: true
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  if (!config.url.includes('authenticate')) {
    config.headers.Authorization = 'Bearer ' + cookie.get('apiKey')
    config.headers.refreshToken = cookie.get('refreshToken')
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  return response
}, async (error) => {
  return Promise.props({
    inventories: inventoryService.getLocalInventories(),
  }).then(async (response) => {
    const offlineInventories = response.inventories.filter(item => item.offlineModified)
    let errorDetail = 'HTTP REQ ERROR : '
    const originalConfig = error.config;
    errorDetail += _.get(error, 'response.status', 'no response status')
    errorDetail += ' '
    if (_.get(error, 'response.status') === 400 && !originalConfig.url.includes('/backup')) {
      await urgencyMode(offlineInventories)
      errorDetail += JSON.stringify(_.get(error, 'response.data.data[0]', 'no info'))
    } else if (_.get(error, 'response.status') === 403) {
      await urgencyMode(offlineInventories)
      cookie.remove('apiKey')
      cookie.remove('refreshToken')
      cookie.remove('expireRefresh')
      if (!window.location.pathname.includes('login')) {
        window.location = '/login'
      }
    } else if (_.get(error, 'response.status') === 401) {
      if (!originalConfig.url.match('/auth/login')) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post('/auth/refresh', {
            refreshToken: cookie.get('refreshToken')
          })
          const { accessToken } = rs.data;
          cookie.set('apiKey', accessToken)
        } catch (_err) {
          await urgencyMode(offlineInventories)
          return Promise.reject(_err)
        }
        return instance(originalConfig)
      } else {
        return Promise.reject(error)
      }
    } else if (_.get(error, 'response.status') === 500) {
      await urgencyMode(offlineInventories)
      errorDetail += JSON.stringify('API INTERN ERROR')
      cookie.remove('apiKey')
      cookie.remove('refreshToken')
      cookie.remove('expireRefresh')
      if (!window.location.pathname.includes('login')) {
        window.location = '/login'
      }
    } else {
      await urgencyMode(offlineInventories)
      return Promise.reject(error)
    }
  })
})
export default instance
