import { SET_API_KEY, SET_REFRESH_TOKEN, SET_EXPIRE_REFRESH } from '../constants/headersTypes'

const initialState = {
  apiKey: null,
  refreshToken: null,
  expireRefresh: null
}

const headers = (state = initialState, action) => {
  switch (action.type) {
    case SET_API_KEY:
      return {
        ...state,
        apiKey: action.value
      }
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.value
      }
    case SET_EXPIRE_REFRESH:
      return {
        ...state,
        expireRefresh: action.value
      }
    default:
      return state
  }
}

export default headers
