import React from 'react';
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next';
import Fab from "@material-ui/core/Fab";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

class Indication extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      target: props.target,
      value: props.value
    }
  }


  render() {

    return (
      <section style={{
        padding: '10px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <div
         style={{
           width:'10%'
         }}>
          <Fab style={{
            background: "#2980b9",
            color: "white"
          }} aria-label="Add" size={'small'}>
            <ErrorOutlineIcon/>
          </Fab>
        </div>
        <div style={{
          width:'90%',
          paddingLeft: '20px'
        }}>
          <h1>{this.state.target.label + ' : ' + this.props.t(this.state.value)}</h1>
        </div>
      </section>
    )
  }
}

export default withRouter(withTranslation()(Indication))
