const initialState = {
  backUrl: process.env.REACT_APP_BACK_URL ? process.env.REACT_APP_BACK_URL : 'https://api.walterre.devel'
  // backUrl: process.env.REACT_APP_BACK_URL ? process.env.REACT_APP_BACK_URL : 'http://api.econeaulogis.com.devel/v1'
}

const constants = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default constants
