import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import NetworkWifi from '@material-ui/icons/NetworkWifi';
import WifiOff from '@material-ui/icons/WifiOff';
import Promise from "bluebird";
import core from 'core';
import cookie from "js-cookie";
import ls from 'local-storage';
import localforage from 'localforage';
import _ from "lodash";
import moment from 'moment';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import InventoryEditComponent from "./Components/Inventory/InventoryEditComponent";
import InventoryListComponent from "./Components/Inventory/InventoryListComponent";
import InventoryMenuComponent from "./Components/Inventory/InventoryMenuComponent";
import LoginComponent from "./Components/Login/LoginComponent";
import PasswordForgottenComponent from "./Components/Login/PasswordForgottenComponent";
import ResetPasswordComponent from "./Components/Login/ResetPasswordComponent";
import NotFoundComponent from "./Components/NotFound/NotFoundComponent";
import inventoryService from './Services/InventoryService';
import axios from './libs/axios';
import prepareData from './libs/prepareData';
import * as HeadersActions from "./redux/actions/headers";
import * as InventoryActions from "./redux/actions/inventory";
import * as MetasActions from "./redux/actions/metas";
import * as NetworkActions from "./redux/actions/network";
import * as UserActions from "./redux/actions/user";
import './static/scss/main.scss';
import populateData from './util/populate';
moment.locale('fr')

class Layout extends React.Component {

  constructor(props) {
    super(props);
    let populate = '';
    populateData.data.forEach((item, index) => {
      populate += item;
      if (index < populateData.data.length - 1) {
        populate += ',';
      }
    });


    let version = _.get(process.env, 'REACT_APP_VERSION', null);
    if (version) {
      version = version.substring(version.length - 4, version.length)
    }

    this.state = {
      render: true,
      version,
      synchronisation: false,
      currentComponent: null,
      expireRefreshToken: cookie.get('expireRefresh') ? moment(new Date(cookie.get('expireRefresh'))).format('DD/MM/YYYY à HH:mm') : 'Date inconnu',
      inventories: [],
      urlParams: {
        populate: populate,
        page: 1,
        limit: 20,
        technician: _.get(props, 'currentUser._id', null)
      }
    };
    if (!props.currentUser && cookie.get('currentUser')) {
      let currentUser = JSON.parse(cookie.get('currentUser'));
      props.userActions.setUser(currentUser);
    }
  }

  handleComponentChange = (name) => {
    if (this.state.currentComponent !== name) {
      this.setState({
        currentComponent: name,
        expireRefreshToken: cookie.get('expireRefresh') ? moment(new Date(cookie.get('expireRefresh'))).format('DD/MM/YYYY à HH:mm') : 'Date inconnu'
      })
    }
  };

  postMedia = (key, object) => {
    return localforage.getItem(key).then(media => {
      let formData = new FormData();
      formData.append('media', new File([media], _.get(object, ['name']), {
        type: _.get(object, ['type']),
        lastModified: Date.now()
      }));
      return axios.post('/media', formData);
    })
  };


  push = () => {
    let isOnline = navigator.onLine;
    return isOnline && ls('push') ? Promise.props({
      inventories: inventoryService.getLocalInventories(),
      // newMetas: inventoryService.getLocalNewMetas(),
      mediasKeys: localforage.keys()
    }).then(resp => {

      let modifiedInventories = [];
      let postedMedias = [];
      let modifiedVentilation = []
      let modifiedAirConditioning = []

      // let newMetas = [];
      // resp.newMetas.forEach(meta => {
      //   meta.value = meta.label;
      //   newMetas.push(axios.put('/metas/' + meta.id, meta))
      // });

      // return Promise.all(newMetas).then(metasResp => {
      //   let cleanMetas = [];
      //   metasResp.forEach(metaResp => {
      //     if (metaResp) {
      //       cleanMetas.push(inventoryService.setMetas(metaResp.data))
      //     }
      //   });

      // return Promise.all(cleanMetas).then(() => {
      for (let i = 0; i < resp.inventories.length; i++) {
        if (resp.inventories[i].offlineModified === true) {
          let modifiedInventory = null;
          resp.inventories[i].offlineModifiedSection.forEach(path => {
            if (!modifiedInventory) {
              modifiedInventory = {
                _id: resp.inventories[i].id,
                offlineModifiedSection: resp.inventories[i].offlineModifiedSection
              };
            }
            _.set(modifiedInventory, path, _.get(resp.inventories[i], path));
          });

          if (modifiedInventory) {
            Object.keys(_.omit(modifiedInventory, ['_id', 'offlineModifiedSection', 'checkedSections'])).forEach(currentMenu => {
              Object.keys(_.omit(modifiedInventory[currentMenu], ['_id'])).forEach(currentSubMenu => {
                let currentPath = currentMenu + '.' + currentSubMenu;
                if (_.get(modifiedInventory, currentPath, false)
                  && _.get(modifiedInventory, currentPath).length > 0
                  && _.get(core.files, currentPath + '.loopForm.type', false) === '*') {
                  _.get(modifiedInventory, currentPath).forEach((item, index) => {
                    item._id = null;
                    let tmpDescFile = _.cloneDeep(core.files);
                    _.set(tmpDescFile, currentPath, null);
                    _.set(tmpDescFile, currentPath + '[' + index + ']', _.get(core.files, currentPath + '.loopForm.fields'));
                    prepareData(item, currentPath + '[' + index + ']', tmpDescFile)
                  })
                } else {
                  prepareData(_.get(modifiedInventory, currentPath), currentPath);
                }
              });
            });
            const result = resp.mediasKeys.filter(key => {
              let inventoryId = key.split(':')[0];
              return parseInt(inventoryId, 10) === parseInt(modifiedInventory._id, 10);
            })
            result.forEach((key) => {
              let exist = false;
              let path = key.split(':')[1];
              let isArray = false;
              let index = null;
              if (path[path.length - 1] === ']') {
                isArray = true;
                path = path.substr(0, path.length - 3)
              }

              if (isArray) {
                _.get(modifiedInventory, path, []).forEach((item, itemIndex) => {
                  if (item.id === key) {
                    exist = true;
                    index = itemIndex;
                  }
                })

              } else {
                if (_.get(modifiedInventory, path, {}).id === key) {
                  exist = true
                }
              }
              if (exist) {
                let postParam = isArray ? _.get(modifiedInventory, path, [])[index] : _.get(modifiedInventory, path, {});
                postedMedias[key] = this.postMedia(key, postParam);
              }
            });

            modifiedInventory.checkedSections = resp.inventories[i].checkedSections;
            const { idVentilationInventory, ventilationInventory, locationOne, locationTwo, locationThree, locationFour, locationFive, locationSix, locationSeven, locationEight, idAirConditioningInventory, airConditioningInventory, airConditioningLocationOne, airConditioningLocationTwo, airConditioningLocationThree, airConditioningLocationFour, airConditioningLocationFive, airConditioningLocationSix, airConditioningLocationSeven, airConditioningLocationEight, ...boilerRoomInventory } = resp.inventories
            modifiedVentilation = { ventilationInventory, locationOne, locationTwo, locationThree, locationFour, locationFive, locationSix, locationSeven, locationEight, id: idVentilationInventory }
            modifiedAirConditioning = {airConditioningInventory, airConditioningLocationOne, airConditioningLocationTwo, airConditioningLocationThree, airConditioningLocationFour, airConditioningLocationFive, airConditioningLocationSix, airConditioningLocationSeven, airConditioningLocationEight, id:idAirConditioningInventory }
            modifiedInventories.push(resp.inventories[i]);
          }
        }


      }

      return Promise.props(postedMedias).then(postedMediasRes => {
        _.forOwn(postedMediasRes, (value, key) => {

          let targetInventoryId = key.split(':')[0];
          let path = key.split(':')[1];
          modifiedInventories.filter(item => parseInt(item.id, 10) === parseInt(targetInventoryId, 10)).forEach(item => {
            if (_.get(value, 'data.id')) {
              _.set(item, path, value.data.id)
            }
          })
        });

        return Promise.each(modifiedInventories, modifiedInventory => {
          // return Promise.each(modifiedInventory.boilerRoomInventory.offlineModifiedSection, path => {
          //   let tmpSectionInventory = {
          //     _id: modifiedInventory._id,
          //     checkedSections: modifiedInventory.checkedSections
          //   };
          //   _.set(tmpSectionInventory, path, _.get(modifiedInventory, path))
          const { idVentilationInventory, ventilationInventory, locationOne, locationTwo, locationThree, locationFour, locationFive, locationSix, locationSeven, locationEight, idAirConditioningInventory, airConditioningInventory, airConditioningLocationOne, airConditioningLocationTwo, airConditioningLocationThree, airConditioningLocationFour, airConditioningLocationFive, airConditioningLocationSix, airConditioningLocationSeven, airConditioningLocationEight, ...boilerRoomInventory } = modifiedInventory
          const modifiedVentilation = { ventilationInventory, locationOne, locationTwo, locationThree, locationFour, locationFive, locationSix, locationSeven, locationEight, id: idVentilationInventory }
          const  modifiedAirConditioning = {airConditioningInventory, airConditioningLocationOne, airConditioningLocationTwo, airConditioningLocationThree, airConditioningLocationFour, airConditioningLocationFive, airConditioningLocationSix, airConditioningLocationSeven, airConditioningLocationEight, id:idAirConditioningInventory }
          const updateBoilerRoom = axios.put('/boiler-room-inventory/' + modifiedInventory.id, modifiedInventory);
          // const updateBoilerRoom = true
          const updateVentilation = axios.put('/ventilation-inventory/' + modifiedInventory.idVentilationInventory, modifiedVentilation);
          const updateAirConditioning = axios.put('/airConditioning-inventory/' + modifiedInventory.idAirConditioningInventory, modifiedAirConditioning);

          return Promise.all([updateBoilerRoom, updateVentilation, updateAirConditioning]).then(() => {
            return ('Push ----> ' + true);
          })
          // })
        })

      })
      // })
      // })
    }) : ('Push ----> ' + false)
  };

  sync = () => {
    if (!this.state.synchronisation) {
      this.setState({ render: false, synchronisation: true }, () => {
        if (!navigator.onLine) {
          this.setState({ render: true }, () => {
            toast.error("Synchronisation impossible ! vous n'êtes pas connécté !")
          })
        } else {
          this.push().then(res => {
            if (navigator.onLine) {
              ls('push', false);
              this.setState({
                render: true,
                synchronisation: false
              }, () => {
                toast.success('les données ont été synchronisées !')
              })
            } else {
              this.setState({
                render: true,
                synchronisation: false
              }, () => {
                toast.success("les données n'ont pas été synchronisées !")
              })
            }
          })
        }
      })
    }

  }


  render() {
    let { render } = this.state;
    if (window.tidioChatApi) {
      window.tidioChatApi.show()
    }
    let appSection = (render) ? (<Router>
      <Switch>
        <Route path="/" exact={true} component={() => <InventoryListComponent myName={this.handleComponentChange} />} />
        <Route path="/login" exact={true} component={() => <LoginComponent myName={this.handleComponentChange} />} />
        <Route path="/password-forgotten" exact={true} component={() => <PasswordForgottenComponent myName={this.handleComponentChange} />} />
        <Route path="/reset-password" exact={true} component={() => <ResetPasswordComponent myName={this.handleComponentChange} />} />
        <Route path="/menu/:id" exact={true} component={InventoryMenuComponent} />

        {
          /* General infos*/
        }
        <Route path="/generalInformations/visitInformations/:id" exact={true}
          component={InventoryEditComponent} />
        <Route path="/generalInformations/boilerExternalInspection/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/generalInformations/others/:id" exact={true} component={InventoryEditComponent} />

        {
          /* Local Boiler */
        }

        <Route path="/boilerRoom/room/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/boilerRoom/fireSafety/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/boilerRoom/ventilation/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/boilerRoom/lowVentilation/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/boilerRoom/highVentilation/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/boilerRoom/boilerFrontDoor/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/boilerRoom/sweeping/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/boilerRoom/boilerState/:id" exact={true} component={InventoryEditComponent} />

        {
          /* Générateurs */
        }


        <Route path="/generators/generatorsOverview/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/generators/boilerView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/generators/burnerView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/generators/smokePipesView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/generators/smokesPipesConnections/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/generators/heatExchanger/:id" exact={true} component={InventoryEditComponent} />


        {
          /* Circuit de distribution */
        }

        <Route path="/distributionNetwork/primaryNetwork/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/distributionNetwork/secondaryNetwork/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/distributionNetwork/ecsNetwork/:id" exact={true} component={InventoryEditComponent} />

        {
          /* Eau et expansion */
        }

        <Route path="/waterExpansion/expansionView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/waterExpansion/heatingNetworkFilling/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/waterExpansion/heatingWaterTreatment/:id" exact={true} component={InventoryEditComponent} />

        {
          /* Ecs production*/
        }
        <Route path="/ecsProduction/preparer/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ecsProduction/ecsProductionView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ecsProduction/coldWaterSupply/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ecsProduction/ecsLoop/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ecsProduction/solarAppoint/:id" exact={true} component={InventoryEditComponent} />

        {
          /* Regulation && Electricité */
        }


        <Route path="/electricityRegulation/electricityView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/electricityRegulation/regulationView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/electricityRegulation/regSettingsHeatingNetwork/:id" exact={true}
          component={InventoryEditComponent} />

        {
          /* Test && prélèv */
        }

        <Route path="/sampleAndMeasurements/sample/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/sampleAndMeasurements/qualityHeatingWaterTest/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/sampleAndMeasurements/qualitySolarWaterTest/:id" exact={true} component={InventoryEditComponent} />


        {
          /* Distribution émetteur */
        }

        <Route path="/distribution/boilerNetwork/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/distribution/transmitter/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/distribution/sizing/:id" exact={true} component={InventoryEditComponent} />

        {
          /* Ss station*/
        }
        <Route path="/subStations/subStationsView/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/subStations/subNetworksView/:id" exact={true} component={InventoryEditComponent} />

        {
          /* Avis technicien */
        }
        <Route path="/commentsAndAnnex/documentList/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/commentsAndAnnex/pictures/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/commentsAndAnnex/technicianAdvices/:id" exact={true} component={InventoryEditComponent} />
        {
          /* Ventilation */
        }
        <Route path="/ventilationInventory/generalInformationVentilation/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/ventilationInventory/locationV8/:id" exact={true} component={InventoryEditComponent} />



        {
          /* locationOne */
        }
        <Route path="/locationOne/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationOne/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationOne/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationOne/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationOne/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationOne/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationOne/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationOne/equipment8/:id" exact={true} component={InventoryEditComponent} />

        {
          /* locationTwo */
        }
        <Route path="/locationTwo/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationTwo/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationTwo/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationTwo/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationTwo/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationTwo/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationTwo/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationTwo/equipment8/:id" exact={true} component={InventoryEditComponent} />
        {
          /* locationThree */
        }
        <Route path="/locationThree/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationThree/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationThree/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationThree/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationThree/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationThree/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationThree/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationThree/equipment8/:id" exact={true} component={InventoryEditComponent} />
        {
          /* locationFour */
        }
        <Route path="/locationFour/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFour/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFour/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFour/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFour/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFour/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFour/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFour/equipment8/:id" exact={true} component={InventoryEditComponent} />


        {
          /* locationFive */
        }
        <Route path="/locationFive/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFive/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFive/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFive/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFive/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFive/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFive/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationFive/equipment8/:id" exact={true} component={InventoryEditComponent} />
        {
          /* locationSix */
        }
        <Route path="/locationSix/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSix/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSix/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSix/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSix/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSix/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSix/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSix/equipment8/:id" exact={true} component={InventoryEditComponent} />

        {
          /* locationSeven */
        }
        <Route path="/locationSeven/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSeven/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSeven/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSeven/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSeven/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSeven/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSeven/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationSeven/equipment8/:id" exact={true} component={InventoryEditComponent} />

        {
          /* locationEight */
        }
        <Route path="/locationEight/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationEight/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationEight/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationEight/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationEight/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationEight/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationEight/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/locationEight/equipment8/:id" exact={true} component={InventoryEditComponent} />

        {
          /* airConditioningInventory */
        }
        <Route path="/airConditioningInventory/generalInformationAirConditioning/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningInventory/locationV8/:id" exact={true} component={InventoryEditComponent} />



        {
          /* airConditioningLocationOne */
        }
        <Route path="/airConditioningLocationOne/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationOne/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationOne/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationOne/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationOne/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationOne/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationOne/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationOne/equipment8/:id" exact={true} component={InventoryEditComponent} />

        {
          /* airConditioningLocationTwo */
        }
        <Route path="/airConditioningLocationTwo/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationTwo/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationTwo/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationTwo/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationTwo/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationTwo/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationTwo/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationTwo/equipment8/:id" exact={true} component={InventoryEditComponent} />
        {
          /* airConditioningLocationThree */
        }
        <Route path="/airConditioningLocationThree/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationThree/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationThree/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationThree/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationThree/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationThree/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationThree/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationThree/equipment8/:id" exact={true} component={InventoryEditComponent} />
        {
          /* airConditioningLocationFour */
        }
        <Route path="/airConditioningLocationFour/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFour/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFour/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFour/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFour/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFour/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFour/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFour/equipment8/:id" exact={true} component={InventoryEditComponent} />


        {
          /* airConditioningLocationFive */
        }
        <Route path="/airConditioningLocationFive/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFive/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFive/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFive/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFive/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFive/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFive/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationFive/equipment8/:id" exact={true} component={InventoryEditComponent} />
        {
          /* airConditioningLocationSix */
        }
        <Route path="/airConditioningLocationSix/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSix/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSix/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSix/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSix/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSix/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSix/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSix/equipment8/:id" exact={true} component={InventoryEditComponent} />

        {
          /* airConditioningLocationSeven */
        }
        <Route path="/airConditioningLocationSeven/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSeven/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSeven/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSeven/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSeven/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSeven/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSeven/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationSeven/equipment8/:id" exact={true} component={InventoryEditComponent} />

        {
          /* airConditioningLocationEight */
        }
        <Route path="/airConditioningLocationEight/equipment1/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationEight/equipment2/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationEight/equipment3/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationEight/equipment4/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationEight/equipment5/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationEight/equipment6/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationEight/equipment7/:id" exact={true} component={InventoryEditComponent} />
        <Route path="/airConditioningLocationEight/equipment8/:id" exact={true} component={InventoryEditComponent} />


        {
          /* 404 PAGE */
        }
        <Route component={NotFoundComponent} />


      </Switch>
    </Router>) : (
      <section style={{
        position: 'absolute',
        height: '50%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '12.5%'
      }}>
        <LinearProgress />
        <section
          style={{
            textAlign: 'center'
          }}
        >
          <h1>Synchronisation ...</h1>
        </section>
        <LinearProgress />
      </section>
    );
    return (
      <main role="main">
        {
          this.state.render && this.state.currentComponent !== 'login' && this.state.currentComponent !== 'password-forgotten' && this.state.currentComponent !== 'reset-password' ? (
            <section style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px'
            }}>
              <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}>
                <div style={window.innerWidth < 500 ? {
                  width: '40%',
                  display: 'flex',
                  fontSize: '12px',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: '2px'
                } :
                  {
                    width: '40%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: '2px'
                  }}>
                  Etat : {navigator.onLine ? 'Connecté' : ' Offline'}
                </div>
                <div style={{
                  width: '20%',
                  minWidth: '130px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2px'
                }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={this.sync}
                    disabled={!ls('push') || !navigator.onLine}
                  >
                    Synchroniser
                  </Button>


                </div>


                <div style={{
                  width: '40%',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '2px'
                }}>
                  {
                    navigator.onLine ? (
                      <NetworkWifi
                        style={{
                          color: '#27ae60'
                        }}
                        fontSize="large" />
                    ) : (
                      <WifiOff
                        style={{
                          color: '#e67e22'
                        }}
                        fontSize="large" />
                    )
                  }
                </div>
              </div>
              <div style={{
                marginTop: '5px',
                fontSize: '1em'
              }}>
                avant le : {this.state.expireRefreshToken}
              </div>
            </section>
          ) : ''
        }
        {appSection}
        <ToastContainer />
        <div style={{
          position: 'absolute',
          top: 0,
          left: 30,
          fontSize: '12px',
          fontWeight: 'bold'
        }}>
          <p>Version : {_.get(this.state, 'version', "n'est pas disponible")}</p>
        </div>
      </main>
    );
  }
}


const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  refreshToken: state.headers.refreshToken,
  expireRefresh: state.headers.expireRefresh,
  currentUser: state.user.user,
  backUrl: state.constants.backUrl,
  inventory: state.inventory.inventory,
  network: state.network.network,
  allMetas: state.metas.all
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  metasActions: bindActionCreators(MetasActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch),
  networkActions: bindActionCreators(NetworkActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));



