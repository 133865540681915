import {SET_OFFLINE, SET_ONLINE} from '../constants/networkTypes'

const initialState = {
  network: true
};

const network = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONLINE:
      return {
        ...state,
        network: true
      };
    case SET_OFFLINE:
      return {
        ...state,
        network: false
      };
    default:
      return state
  }
};

export default network;
