import CircularProgress from '@material-ui/core/CircularProgress'
import Build from '@material-ui/icons/Build'
import CheckCircle from '@material-ui/icons/CheckCircle'
import EventIcon from '@material-ui/icons/Event'
import Person from '@material-ui/icons/Person'
import Phone from '@material-ui/icons/Phone'
import Place from '@material-ui/icons/Place'
import VpnKey from '@material-ui/icons/VpnKey'
import Promise from 'bluebird'
import core from "core"
import ls from 'local-storage'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'
import Actions from '../../Fields/Actions'
import inventoryService from '../../Services/InventoryService'
import * as HeadersActions from '../../redux/actions/headers'
import * as InventoryActions from '../../redux/actions/inventory'
import * as NetworkActions from '../../redux/actions/network'
import * as UserActions from '../../redux/actions/user'
import populateData from '../../util/populate'
import Header from '../Header/HeaderComponent'
import { ModalProject } from '../modal/addProject'

class InventoryListComponent extends React.Component {
  constructor(props) {
    super(props)
    let populate = ''
    populateData.data.forEach((item, index) => {
      populate += item
      if (index < populateData.data.length - 1) {
        populate += ','
      }
    })
    this.state = {
      projects: null,
      urlParams: {
        populate: populate,
        page: 1,
        limit: 20,
        stateOfProgress: 'programmed'
      }
    }
  }

  componentDidMount() {
    if (this.props.myName) {
      this.props.myName('list')
    }
    const isOnline = navigator.onLine
    this.setState({ technician: _.get(this.props, 'currentUser._id', false) }, () => {
      // GET DATA
      Promise.props({
        projects: isOnline && !ls('push') ? inventoryService.getRemoteInventories(this.state.urlParams) : inventoryService.getLocalInventories(),
        metas: isOnline && !ls('push') ? inventoryService.getRemoteMetas() : inventoryService.getLocalMetas()
      }).then(resp => {
        if (isOnline && !ls('push')) {
          //CLEAN DB
          Promise.props({
            resetDataBase: inventoryService.resetDataBases()
          }).then(() => {
            // UPDATE DB
            Promise.props({
              setLocalProjects: inventoryService.setLocalProjects(resp.projects),
              setLocalInventories: inventoryService.setLocalInventories(resp.projects),
              setLocalMetas: inventoryService.setLocalMetas(resp.metas)
            }).then(res => {
              this.setState({ projects: resp.projects })
            })
          })
        } else {
          this.setState({ projects: resp.projects })
        }
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!navigator.onLine) {
      if (this.props.network) {
        this.props.networkActions.setOffline(true)
        toast.warn('Le mode Offline est activé !')
      }
    } else {
      if (!this.props.network) {
        this.props.networkActions.setOnline(true)
        toast.success('Vous êtes connécté !')
      }
    }
  }

  render() {
    const { projects } = this.state
    if (window.tidioChatApi) {
      window.tidioChatApi.show()
    }
    const inventoriesSection = (projects && projects.length > 0) ? (
      projects.map((project, index) => {
        const address = `${project.streetNumber} ${project.streetName} ${project.zipcode} ${project.city}`
        return (
          (project) ? (
            <Link
              to={{
                pathname: _.get(project, 'id', null) ? '/menu/' + _.get(project, 'id', null) : '',
                query: {
                  selectedInventory: _.get(project, 'id', null)
                }
              }}
              key={index}
              className='card-schedule-wrapper'

            >
              <article className='card-schedule'>
                <time className='card-schedule__time'>
                  <div>
                    <span>
                      {(<Moment format='DD' locale='fr'>
                        {project.interventionDate}
                      </Moment>)}
                    </span>
                    {(<Moment format='MMMM YYYY' locale='fr'>
                      {project.interventionDate}
                    </Moment>)}
                  </div>
                </time>
                <div className='card-schedule__content'>
                  <p className='card-schedule__content__title'>
                    {project ? project.name : ''}
                    <span
                      style={{
                        fontSize: "0.8rem",
                        marginLeft: "5px",
                        textTransform: "lowercase",
                      }}
                    >
                      {_.get(project, 'id', false) ? '' : '(non disponible)'}
                    </span>
                  </p>
                  <ul className='card-schedule__infos'>

                    <li className='card-schedule__infos__item' style={{ margin: '20px' }}>
                      <Place />
                      {
                        address ? (
                          <p>{address}</p>
                        ) : 'NC'
                      }
                    </li>

                    <li className='card-schedule__infos__item' style={{ margin: '20px' }}>
                      <Phone />
                      <p>{_.get(project, 'onSiteContact.phone', 'NC')}</p>
                    </li>

                    <li className='card-schedule__infos__item' style={{ margin: '20px' }}>
                      <Person />
                      <p>{_.get(project, 'onSiteContact.fullname', 'NC')}</p>
                    </li>

                    <li className='card-schedule__infos__item' style={{ margin: '20px' }}>
                      <VpnKey />
                      <p>{core.translations[_.get(project, 'boilerRoomKeys', 'NC')] ?? _.get(project, 'boilerRoomKeys', 'NC')}</p>
                    </li>

                  </ul>

                  <div className='card-schedule__infos'>
                    <p>{_.get(project, 'comment', 'NC')}</p>
                  </div>

                </div>
                <div className='card-schedule__status'>
                  <div>

                    {
                      moment(project.interventionDate).isAfter(new Date()) ? (
                        <div className='card-schedule__status--doing'>
                          <EventIcon fontSize='large' />
                          <p style={{ textAlign: "center" }}>{_.get(core.translations, project.interventionType, 'Programmé')}</p>
                        </div>
                      ) : (project.isFinished) ? (<div className='card-schedule__status--done'>
                        <CheckCircle fontSize='large' />
                        <p style={{ textAlign: "center" }}>{_.get(core.translations, project.interventionType, 'Terminé')}</p>
                      </div>)
                        : (<div className='card-schedule__status--doing'>
                          <Build fontSize='large' />
                          <p style={{ textAlign: "center" }}>{_.get(core.translations, project.interventionType, 'En cours')}</p>
                        </div>)
                    }

                  </div>
                </div>
              </article>
            </Link>
          ) : ''
        )
      })
    ) : (projects && projects.length === 0) ? (
      <section style={{
        position: 'absolute',
        width: '100%',
        left: '0',
        top: '40%'
      }}
      >
        <span className='errorTextContainer'>
          <span className='errorTextImportant'>OUPS!</span>
          <br />
          <span className='errorText'>Vous n'avez aucun état des lieux pour le moment :(</span>
        </span>
      </section>
    ) : ''

    const spinnerSection = (!projects) ? (
      <section className='spinner-container'>
        <CircularProgress
          size={70}
          color='secondary'
        />
      </section>
    ) : ''

    return (
      <section>
        <Header title='Mes états des lieux' />
        <div className='wrapper'>
          {inventoriesSection}
        </div>
        <Actions />
        {spinnerSection}
        {console.log('this.props', this.props)}
        <ModalProject technician={_.get(this.props, 'currentUser._id', false)} />
      </section>
    )
  }
}

const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  refreshToken: state.headers.refreshToken,
  currentUser: state.user.user,
  backUrl: state.constants.backUrl,
  inventory: state.inventory.inventory,
  network: state.network.network
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch),
  networkActions: bindActionCreators(NetworkActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(InventoryListComponent)))
