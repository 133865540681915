import React from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import _ from "lodash";

class Input extends React.Component {
  constructor(props) {
    super(props);
    let id = uniqid(props.name + '-');
    this.state = {
      value: props.value ? props.value : '',
      id,
      hasError: true,
      errorMessage: props.errorMessage,
      hasBlured: false,
    };

  }


  UNSAFE_componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    if (nextProps.value && nextProps.value !== this.state.value) {
      this.setState({value: nextProps.value})
    }

  }

  debounce = _.debounce((value) => {
    if(!value) {
      value=null;
    }
    this.props.onChange(this.props.name, value, this.props.index);
  }, this.props.debounce !== undefined ? this.props.debounce : 500, {});

  handleChange = (e) => {
    if (this.state.hasBlured && this.props.validate) {
      this.setState({
        hasError: !this.props.validate(e.target.value),
      });
    }
    this.setState({
      value: e.target.value
    }, () => {
      this.debounce(this.state.value)
    })
  };


  render() {

    return (
      <div>
        <FormControl
          error={this.state.hasError && this.state.hasBlured}
          variant="outlined"
          style={{ width: '100%' }}
        >
          <TextField
            inputProps={{
              autoComplete: this.props.autoComplete ? this.props.autoComplete : null,
              min: this.props.min
            }}
            InputLabelProps={{shrink: !!(this.state.value)}}
            multiline={this.props.multiline ? this.props.multiline : false}
            label={this.props.label}
            min={this.props.min}
            value={this.state.value}
            type={this.props.type}
            onChange={this.handleChange}
            variant="outlined"
            disabled={this.props.disabled ? this.props.disabled : false}
          />
          {this.state.hasError &&
          this.state.hasBlured && (
            <FormHelperText id={this.state.id + '-text'}>
              {this.state.errorMessage}
            </FormHelperText>
          )} 
        </FormControl>
      </div>
    );
  }
}

Input.defaultProps = {
  value: '',
  type: 'text',
  name: uniqid(),
  min: null,
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'tel',
    'search',
    'password',
    'url',
  ]),
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
  min: PropTypes.number
};

export default Input;
