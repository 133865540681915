import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Isemail from 'isemail';
import cookie from 'js-cookie';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import Input from "../../Fields/Input";
import inventoryService from '../../Services/InventoryService';
import axios from '../../libs/axios';
import * as HeadersActions from '../../redux/actions/headers';
import * as UsersActions from '../../redux/actions/user';

class PasswordForgottenComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false
    };
  }

  componentDidMount() {
    this.props.myName('login')
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  login = (e) => {
    e.preventDefault();

    if (navigator.onLine) {
      if (Isemail.validate(_.get(this.state, 'email', '')) && _.get(this.state, 'password', '').length > 0) {

        axios.post('/auth/login', {
          email: this.state.email,
          password: this.state.password,
        }).then(response => {
          let expires = new Date(_.get(response, 'data.refreshExpiration'))
          let currentUser = {
            email: response.data.user.contact.email,
            roles: [response.data.user.team],
            _id: response.data.user.id
          };
          cookie.set('apiKey', response.data.accessToken)
          cookie.set('refreshToken', response.data.refreshToken)
          cookie.set('expireRefresh', expires)
          if (cookie.get('currentUser')) {
            let lastUser = JSON.parse(cookie.get('currentUser'));
            if (lastUser.email !== currentUser.email || lastUser._id !== currentUser._id) {
              inventoryService.resetDataBases().then(() => {
                cookie.set('currentUser', JSON.stringify(currentUser), { expires });
                this.props.usersActions.setUser(currentUser)
                this.props.history.push('/');
              })
            } else {
              cookie.set('currentUser', JSON.stringify(currentUser), { expires });
              this.props.history.push('/');
            }
          } else {
            cookie.set('currentUser', JSON.stringify(currentUser), { expires });
            this.props.history.push('/');
          }

        }).catch(err => {
          this.setState({
            id: '',
            password: '',
            vibrateClass: 'shake-horizontal shake-constant shake-constant-hover',
            fail: true
          }, () => {
            toast.error(`Email ou mot de passe incorrect.`);
          });

          setTimeout(() => {
            this.setState({
              vibrateClass: ''
            });
          }, 200);
        });
      } else {
        if (!Isemail.validate(_.get(this.state, 'email', ''))) {
          toast.error("L'adresse email n'est pas valide")
        } else {
          if (_.get(this.state, 'password', []).length === 0) {
            toast.error('Veuillez rentrer votre mot de passe')
          }
        }
      }
    } else {
      toast.error('Pas de connexion internet')
    }


  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    return (

      <form onSubmit={this.login}
        style={window.innerWidth < 500 ? {
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        } : {
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          width: '60%',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }} className={this.state.vibrateClass}
      >
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          style={window.innerWidth < 500 ? {
            flexFlow: 'column nowrap'
          } : {
            padding: '30px',
            flexFlow: 'column nowrap'
          }}
          spacing={25}
        >
          <Grid item xs={5}>
            <img alt="logo" src="/logo.png" width="200" />
          </Grid>

          <Grid item>
            <p>
              La version que vous utilisez est obsolète, pensez à synchroniser vos projets puis à supprimer l'application de votre appareil.
              Une nouvelle version de l'application est disponible ici:
            </p>

            <a
              href={"https://app.waltapp.fr/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              WaltApp
            </a>
          </Grid>

          <Grid item xs={5} style={window.innerWidth < 500 ? {
            width: '100%'
          } : {
            width: '30%'
          }}>
            <Input
              autoComplete={'email'}
              name={'email'}
              type={'email'}
              value={this.state.email}
              onChange={this.handleChange}
              debounce={0}
              label={'Email'}
            />
          </Grid>


          <Grid item xs={5} style={window.innerWidth < 500 ? {
            width: '100%',
            textAlign: 'center'

          } : {
            width: '30%',
            textAlign: 'center'

          }}>
            <FormControl variant="outlined" style={{
              width: '100%'
            }}>
              <InputLabel htmlFor="new-password">Mot de passe</InputLabel>
              <OutlinedInput
                key="Mot de passe"
                autoComplete='off'
                id="new-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={e => this.handleChange('password', e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={95}
              />
            </FormControl>
          </Grid>

          <Grid item xs={5}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >Connexion</Button>
          </Grid>
          <Grid item xs={5}>
            <Link
              to={'/password-forgotten'}
            >
              Mot de passe oublié
            </Link>
          </Grid>
        </Grid>
      </form>

    );
  }
}


const mapStateToProps = state => ({
  backUrl: state.constants.backUrl
});

const mapDispatchToProps = dispatch => ({
  headersActions: bindActionCreators(HeadersActions, dispatch),
  usersActions: bindActionCreators(UsersActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordForgottenComponent));
