import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import Input from "../../Fields/Input";
import axios from '../../libs/axios';
import * as HeadersActions from '../../redux/actions/headers';
import * as UserActions from '../../redux/actions/user';


class PasswordForgottenComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    this.props.myName('password-forgotten')
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };


  requestPasswordReset = (e) => {
    e.preventDefault();
    axios.patch('/user/forgot/password', {
      email: this.state.email,
    }).then((response) => {
      toast.success('Si nous connaissons cet email, un email vous sera envoyé pour changer votre mot de passe.')
      this.props.history.push('/login')
    }).catch(() => {
      toast.error('Une erreur est survenue, contactez notre service client')
      this.props.history.push('/login')
    })
  };

  render() {
    return (


      <form onSubmit={this.requestPasswordReset}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          width: '60%',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }} className={this.state.vibrateClass}
      >
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          style={{
            padding: '30px',
            flexFlow: 'column nowrap'
          }}
          spacing={32}
        >
          <Grid item xs={5}>
            <img alt="logo" src="/logo.png" width="200" />
          </Grid>
          <Grid item xs={5}>
            <h1 className={'pf-form-title'}>Mot de passe oublié</h1>
          </Grid>
          <Grid item xs={5}>
            <Input
              autoComplete={'email'}
              name={'email'}
              type={'email'}
              value={this.state.email}
              onChange={this.handleChange}
              debounce={0}
              label={'Email'}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >valider</Button>
          </Grid>
          <Grid item xs={5}>
            <Link
              to={'/login'}
            >
              Annuler
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }
}


const mapStateToProps = state => ({
  apiKey: state.headers.apiKey,
  currentUser: state.user.user,
  refreshToken: state.headers.refreshToken,
  backUrl: state.constants.backUrl
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordForgottenComponent));


