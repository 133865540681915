import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import Input from "../../Fields/Input";
import axios from '../../libs/axios';
import * as HeadersActions from '../../redux/actions/headers';
import * as UserActions from '../../redux/actions/user';

class ResetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      id: null,
      token: null
    };
  }


  componentDidMount() {
    this.props.myName('reset-password')
    const { token, id } = queryString.parse(this.props.location.search);
    if (token && id) {
      const isForgot = true
      this.setState({ token, id, isForgot })
    } else {
      this.props.history.push('/login')
    }
  }


  handleChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  changePassword = (e) => {
    e.preventDefault();


    if (this.state.password && this.state.password.length < 6) {
      toast.error('Votre mot de passe doit contenir au moins 6 caractères.')
    } else if (this.state.password !== this.state.confirmPassword) {
      toast.error('Vos mots de passes ne correspondent pas.')
    } else {
      axios.put('/account/password-reset', {
        id: this.state.id,
        token: this.state.token,
        password: this.state.password
      }).then((response) => {
        toast.success('Le mot de passe a bien été changé.');
        this.setState({ fail: false });
        this.props.history.push('/');
      }).catch((err) => {
        toast.error('Le lien est expiré, merci de refaire une nouvelle demande.');
        this.setState({
          password: '',
          confirmPassword: ''
        }, () => {
          this.props.history.push('/');
        });

      });
    }
  };


  render() {
    return (
      <form onSubmit={this.changePassword}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          width: '60%',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }} className={this.state.vibrateClass}
      >
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          style={{
            padding: '30px',
            flexFlow: 'column nowrap'
          }}
          spacing={32}
        >
          <Grid item xs={5}>
            <img alt="logo" src="/logo.png" width="200" />
          </Grid>

          <Grid item xs={5}>
            <h1 className={'pf-form-title'}> Réinitialiser mon mot de passe</h1>
          </Grid>
          <Grid item xs={5}>
            <Input
              name={'password'}
              type={'password'}
              value={this.state.password}
              onChange={this.handleChange}
              debounce={0}
              label={'Nouveau mot de passe'}
            />
          </Grid>

          <Grid item xs={5}>
            <Input
              name={'confirmPassword'}
              type={'password'}
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              debounce={0}
              label={'Confirmation'}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >Modifier</Button>
          </Grid>
          <Grid item xs={5}>
            <Link
              to={'/login'}
            >
              Annuler
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }
}


const mapStateToProps = state => ({
  backUrl: state.constants.backUrl
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  headersActions: bindActionCreators(HeadersActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordComponent));
