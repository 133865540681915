import {SET_INVENTORY} from '../constants/inventoryTypes'

const initialState = {
  inventory: null
};

const inventory = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVENTORY:
      return {
        ...state,
        inventory: action.value
      };
    default:
      return state
  }
};

export default inventory;
