import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import CloudUpload from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomIn from '@material-ui/icons/ZoomIn';
import imageCompression from 'browser-image-compression';
import _ from 'lodash';
import React, { Component } from 'react';
import axios from './../libs/axios';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});

class Upload extends Component {

  constructor(props) {
    super(props);
    let file = props.file ? props.file : null;
    let files = props.files ? props.files : null;
    if (files && props.multiple) {
      files.forEach(file => {
        if (file.originalname) {
          file.name = file.originalname;
        }
      });
      file = null;
    } else {
      if (file && !file.name) {
        file.name = file.originalname;
      }
    }

    this.state = {
      file: file,
      previewImage: file && file.url ? file.url : (file && file.previewImage ? file.previewImage : file && file.file ? file.file : null),
      fileName: file && _.get(file, 'name', false) ? file.name.split('.')[0] : null,
      previewImageDialog: false,
      editTitleDialog: false,
      files: props.files ? props.files : [],
      multiple: props.multiple,
      isLoading: false,
      uploadParams: {
        maxSizeMB: this.props.highQuality ? 3 : 0.1,
        maxWidthOrHeight: this.props.highQuality ? undefined : 700,
        useWebWorker: true
      }
    };
  }


  openPreviewImageDialog = (file) => {
    this.setState({
      previewImage: file.url ? file.url : file.previewImage ? file.previewImage : file.file ? file.file : this.state.previewImage ? this.state.previewImage : null,
      selectedFile: file
    }, () => {
      this.setState({
        previewImageDialog: true
      })
    })
  };


  openEditTitleDialog = (file, index) => {
    if (!file.originalname) {
      file.originalname = file.name;
    }
    let fileName = file.originalname.split('.')[0];
    this.setState({
      selectedFile: file,
      selectedIndex: index !== null ? index : null,
      fileName
    }, () => {
      this.setState({
        editTitleDialog: true,
      })
    });
  };

  previewImageDialogCloseHandler = () => {
    this.setState({
      previewImageDialog: false
    })
  };


  editTitleDialogCloseHandler = () => {
    this.setState({
      editTitleDialog: false
    })
  };


  inputChangeHandler = (value) => {
    this.setState({
      fileName: value
    })
  };


  saveFileName = () => {
    if (this.state.fileName && this.state.fileName.length > 0) {
      let splitedName = this.state.selectedFile.originalname.split('.');
      let extension = splitedName[splitedName.length - 1];
      let newFileName = this.state.fileName + '.' + extension;

      let newFile: any;

      // Where is a new file - before post
      if (!this.state.selectedFile._id) {
        newFile = new File([this.state.selectedFile], newFileName, { type: this.state.selectedFile.type });
        if (this.state.selectedIndex !== null) {
          let files = _.cloneDeep(this.state.files);
          newFile.previewImage = _.cloneDeep(files[this.state.selectedIndex].previewImage);
          files[this.state.selectedIndex] = newFile;
          this.setState({ files, selectedIndex: null, selectedFile: null }, () => {
            this.props.filesChanged(this.props.name, this.state.files);
            this.editTitleDialogCloseHandler();
          });
        } else {
          this.setState({ file: newFile }, () => {
            this.props.fileChanged(this.props.name, this.state.file, this.props.index);
            this.editTitleDialogCloseHandler();
          });
        }
      } else {

        // Already existed - need a put
        let reqBody = {
          _id: this.state.selectedFile._id,
          originalname: newFileName,
        };
        axios.put('/medias/' + reqBody._id, reqBody).then(res => {
          newFile = res.data.data;
          newFile.name = newFileName.originalname;
          if (this.state.selectedIndex !== null) {
            let files = _.cloneDeep(this.state.files);
            files[this.state.selectedIndex] = _.cloneDeep(newFile);
            this.setState({ files, selectedIndex: null, selectedFile: null }, () => {
              this.props.filesChanged(this.props.name, this.state.files);
              this.editTitleDialogCloseHandler();
            });
            this.props.filesChanged(this.props.name, this.state.files);
          } else {
            this.setState({ file: newFile }, () => {
              this.props.fileChanged(this.props.name, this.state.file, this.props.index);
              this.editTitleDialogCloseHandler();
            });
          }
        }).catch(err => {
          console.log(err);
        });
      }
    } else {
      this.setState({
        fileName: this.state.file.originalname.split('.')[0]
      }, () => {
        this.editTitleDialogCloseHandler();
      });
    }
  };


  handleUploadBtnChange = (e) => {


    let file = e.target.files[0];
    let { uploadParams } = this.state;

    if (file) {
      this.setState({
        isLoading: true
      }, () => {
        imageCompression(file, uploadParams).then((compressedFile) => {
          file = new File([compressedFile], file.name, { type: file.type });
          if (this.state.multiple) {
            let files = this.state.files;
            let exist = false;
            files.forEach(item => {
              if (item.name === file.name) {
                exist = true;
              }
            });
            if (!exist) {

              let reader = new FileReader();
              reader.onload = (e) => {

                let index = files ? files.length : 0;
                let stateFiles = _.cloneDeep(files);


                stateFiles.push({
                  url: e.target.result,
                  name: compressedFile.name.length > 0 ? compressedFile.name : 'Aucun nom',
                  isLoading: false
                });

                this.setState({
                  files: stateFiles,
                  isLoading: false
                }, () => {
                  files.push({
                    file: compressedFile,
                    id: this.props.inventoryId + ':' + this.props.name + '[' + index + ']',
                    name: compressedFile.name,
                    type: compressedFile.type
                  });

                  this.props.files.forEach((item, index) => {
                    if (_.get(item, 'file', false) instanceof Blob) {
                      files[index] = item;
                    }
                  });
                  this.props.filesChanged(this.props.name, files, this.props.index);
                })
              };
              reader.readAsDataURL(file);
            }
          } else {
            this.setState({ file }, () => {
              // preview of the image file
              if (file.type && file.type.includes('image')) {
                let reader = new FileReader();
                let currentState = this;

                reader.onload = function (e) {
                  let splitedName = file.name.split('.');
                  let fileName = '';
                  for (let i = 0; i < splitedName.length - 1; i++) {
                    fileName += splitedName[i]
                  }
                  currentState.setState({
                    previewImage: e.target.result,
                    fileName: fileName.length > 0 ? fileName : 'Aucun nom',
                    isLoading: false
                  });
                };

                reader.readAsDataURL(file);

              }
              this.props.fileChanged(this.props.name, {
                file: compressedFile,
                id: this.props.inventoryId + ':' + this.props.name,
                name: compressedFile.name,
                type: compressedFile.type
              }, this.props.index);
            });
          }
        }).catch(function (error) {
          console.log(error.message);
        });
      })
    }
  };

  deleteFile = (index) => {
    if (this.state.multiple) {
      let files = this.state.files;
      files.splice(index, 1);
      this.setState({ files }, () => {
        this.props.filesChanged(this.props.name, this.state.files);
      })
    } else {
      if (this.state.file) {
        this.setState({
          file: null
        }, () => {
          this.props.fileChanged(this.props.name, this.state.file, this.props.index);
        });
      }
    }
  };

  render() {

    const { classes } = this.props;
    let { file, files, isLoading } = this.state;

    let isLoadingSection = (isLoading) ? (
      <CircularProgress
        size={70}
        color={'secondary'}
        style={{ marginBottom: '50px' }}
      />
    ) : ''

    let fileSection = (file) ? (
      <div className="card-upload">
        {/* <Tooltip title="Modifier le titre" placement="top">
          <Button className="card-upload__title" onClick={e => this.openEditTitleDialog(file, null)}>
            {this.state.fileName}
            <Icon className="card-upload__title__icon" aria-label="Edit">edit</Icon>
          </Button>
        </Tooltip> 
        */}

        {
          (this.state.previewImage) ? (
            <figure className="card-upload__img bg-cover" onClick={e => this.openPreviewImageDialog(file)} style={{
              backgroundImage: `url(${this.state.previewImage})`,
            }}>
              <i className="card-upload__img__zoom">
                <ZoomIn />
              </i>
            </figure>
          ) : ''
        }

        <div className="card-upload__delete align-right">
          <Tooltip title="Supprimer" aria-label="Delete" placement="left" onClick={this.deleteFile}>
            <IconButton
              aria-label="Delete"
              style={{
                color: '#d50000'
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    ) : '';


    let filesSection = (files && files.length > 0) ? (
      <div className="card-upload-wrapper">
        {
          files.map((item, index) => {
            return (
              <div key={index} className="card-upload">
                {/* <Tooltip title="Modifier le titre" placement="top">
                  <Button className="card-upload__title" onClick={e => this.openEditTitleDialog(item, index)}>
                    {item.name ? item.name.split('.')[0] : item.name}
                    <Icon className="card-upload__title__icon" aria-label="Edit">edit</Icon>
                  </Button>
                </Tooltip>
 */}
                {
                  (item.url || (item.file && !(item.file instanceof Blob))) ? (
                    <figure className="card-upload__img bg-cover"
                      onClick={e => this.openPreviewImageDialog(item, index)} style={{
                        backgroundImage: `url(${item.url || item.file})`,
                      }}>
                      <i className="card-upload__img__zoom">
                        <ZoomIn />
                      </i>
                    </figure>
                  ) : ''
                }

                <div className="card-upload__delete align-right">
                  <Tooltip title="Supprimer" aria-label="Delete" placement="left" onClick={e => this.deleteFile(index)}>
                    <IconButton
                      aria-label="Delete"
                      style={{
                        color: '#d50000'
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>

              </div>
            )
          })
        }
      </div>
    ) : '';

    return (
      <section className="formSection">
        <h2 className="h2">
          {this.props.label}
        </h2>
        <input
          accept={this.props.accept ? this.props.accept : 'image/*'}
          className={classes.input}
          id={this.props.id}
          type="file"
          onChange={this.handleUploadBtnChange}
        />
        {isLoadingSection}
        {fileSection}
        {filesSection}

        <label htmlFor={this.props.id}>
          <Button
            variant="contained"
            component="span"
            color={'secondary'}
          >
            Ajouter {(this.props.accept && this.props.accept.includes('pdf')) ? 'un document' : 'une image'}
            <CloudUpload style={{ marginLeft: '10px' }} />
          </Button>
        </label>


        {/* {
          (this.state.selectedFile) ? (
            <Dialog open={this.state.editTitleDialog}
                    onClose={this.editTitleDialogCloseHandler}
                    aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Modifier le titre</DialogTitle>
              <DialogContent>
                <TextField
                  key={'fileName'}
                  value={this.state.fileName}
                  autoFocus
                  margin="dense"
                  label="Titre de l'image"
                  type="text"
                  fullWidth
                  onChange={e => this.inputChangeHandler(e.target.value)}
                />
              </DialogContent>
              <DialogActions style={{
                justifyContent: 'center'
              }}>
                <Button onClick={this.editTitleDialogCloseHandler} color="primary">
                  Retour
                </Button>

                <Button onClick={this.saveFileName} color="secondary">
                  Enregistrer
                </Button>
              </DialogActions>
            </Dialog>
          ) : ""
        } */}


        {
          this.state.selectedFile ? (
            <Dialog open={this.state.previewImageDialog}
              onClose={this.previewImageDialogCloseHandler}
              aria-labelledby="form-dialog-title"
              maxWidth={'lg'}
            >
              <DialogTitle
                id="form-dialog-title">{this.state.selectedFile.name ? this.state.selectedFile.name.split('.')[0] : 'pas de nom'}</DialogTitle>
              <DialogContent style={{
                padding: '0'
              }}>
                <img src={this.state.previewImage} width={"100%"} height={"auto"}
                  alt={this.state.selectedFile.originalname} />
              </DialogContent>
              <DialogActions style={{
                justifyContent: 'center'
              }}>
                <Button onClick={this.previewImageDialogCloseHandler} color="primary">
                  Retour
                </Button>
              </DialogActions>
            </Dialog>
          ) : ""
        }


      </section>
    );

  }
}


export default withStyles(styles)(Upload);
